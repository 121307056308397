<div class="wrapper fadeInDown">
    <div id="formContent">
        <div class="fadeIn first">
           <h2><span id="info3">ADMIN LOGIN REQUIRE </span><br></h2>
          </div>
      <form autocomplete="disabled" id="adminLoginForm">
        <input type="hidden" [(ngModel)]="adminData.token" name="token" value="token" id="token">
        <input type="text" [(ngModel)]="adminData.email" name="email" id="email" class="fadeIn second" name="email" placeholder="Email" autocomplete="disabled" autofocus>
        <input type="password" [(ngModel)]="adminData.password" name="name" id="password" class="fadeIn second" name="login" placeholder="password" >
        <input type="button" class="fadeIn fourth" value="Log In" (click)= "loginAdmin()" >

      </form>
    </div>
  </div>
