<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate">
<meta http-equiv="Pragma" content="no-cache">
<meta http-equiv="Expires" content="0">      
      <div class="text-center container-fluid" [ngClass]="{genderOne : gender == 1, genderTwo : gender == 2, genderThree : gender == 3}">
         <table class="table">
          <tbody><tr>
           
            <td style="width:70px"> 
              <img *ngIf="gender == 1" class="img-responsive" style="width:70px; height:55px" src='/src/assets/icons/f/logo-small.png' alt='image'>
              <img *ngIf="gender == 2" class="img-responsive" style="width:70px; height:55px" src='/src/assets/icons/m/logo-small.png' alt='image'>
              <img *ngIf="gender == 3" class="img-responsive" style="width:70px; height:55px" src='/src/assets/icons/m/logo-small.png' alt='image'>
            </td>
            <td >
              <fieldset class="rating"> <input type="radio" id="star5" name="rating" value="5" disabled/><label class="full" for="star5" ></label> <input type="radio" id="star4half" name="rating" value="4.5" disabled/><label class="half" for="star4half" ></label> <input type="radio" id="star4" name="rating" value="4" disabled/><label class="full" for="star4" ></label> <input type="radio" id="star3half" name="rating" value="3.5" disabled/><label class="half" for="star3half" ></label> <input type="radio" id="star3" name="rating" value="3" disabled/><label class="full" for="star3" ></label> <input type="radio" id="star2half" name="rating" value="2.5" disabled /><label class="half" for="star2half" ></label> <input type="radio" id="star2" name="rating" value="2" disabled/><label class="full" for="star2" ></label> <input type="radio" id="star1half" name="rating" value="1.5" disabled/><label class="half" for="star1half" ></label> <input type="radio" id="star1" name="rating" value="1" /><label class="full" for="star1" disabled ></label> <input type="radio" id="starhalf" name="rating" value="0.5" disabled /><label class="half" for="starhalf" ></label> <input type="radio" class="reset-option" name="rating" value="reset" /> </fieldset> 
             </td>
          </tr>   
        </tbody>
      </table>
            <h1 class="display-3 h1" *ngIf="!slang "> <hr style="height:2px;border-width:0;color:gray;background-color:gray"></h1>
              <h1 class="display-3 h2" *ngIf="slang == 'ENG'"> Thank You! <mat-icon style="font-size:27px; color:#7c7e77" >emoji_emotions</mat-icon></h1>
              <h1 class="display-3 h2" *ngIf="slang == 'FIN'"> Kiitos! <mat-icon style="font-size:27px; color:#7c7e77" >emoji_emotions</mat-icon></h1>
              <h1 class="display-3 h2" *ngIf="slang == 'SWE'"> Tack! <mat-icon style="font-size:27px; color:#7c7e77" >emoji_emotions</mat-icon></h1>
             
   <p class="feedback_" *ngIf="feedbackFound"> 
       {{generalFB }} 
     </p>

    <div *ngIf="!feedbackFound">
      <div *ngIf="slang == 'ENG'"> For your time: </div>
      <div *ngIf="slang == 'FIN'"> ajastasi: </div>
      <div *ngIf="slang == 'SWE'"> för din tid: </div>
    </div>
       <hr style="height:2px;border-width:0;color:gray;background-color:gray">

    <p class="feedback" *ngIf="feedbackFound" >
       {{fb1}} <br><br>  {{fb2}} <br><br> {{fb3}} <br><br>  {{fb4}}
    </p>
    <div class="justify-content-center bottom1" *ngIf="feedbackFound"> 
      <div class="input-with-icon d-flex justify-content-between" *ngIf="slang == 'ENG'" >
        <input type="text" name="signup" id="signuptop" class="form-control cst-ctrl" placeholder="Your e-mail">
        <div class="btn22 p-2 label2" (click)="sendmail()" data-toggle="tooltip" title="Click here to send this report to the Email"> Send</div>
        <div class="ml-auto p-2">  </div>
      </div>

      <div class="input-with-icon d-flex justify-content-between" *ngIf="slang == 'FIN'">
        <input type="text" name="signup" id="signuptop" class="form-control cst-ctrl" placeholder="Sähköpostisi">
        <div class="btn22 p-2 label2" (click)="sendmail()" data-toggle="tooltip" title="tämän raportin sähköpostiin"> Lähetä</div>
        <div class="ml-auto p-2">  </div>
      </div>

      <div class="input-with-icon d-flex justify-content-between" *ngIf="slang == 'SWE'">
        <input type="text" name="signup" id="signuptop" class="form-control cst-ctrl" placeholder="Ditt e-mejl">
        <div class="btn22 p-2 label2" (click)="sendmail()" data-toggle="tooltip" title="Klicka här för att skicka denna rapport till e-postmeddelandet"> Sänd</div>
        <div class="ml-auto p-2">  </div>
      </div>
          <span class="cst-error"></span>
                </div>
                <p class="feedback_n" *ngIf="feedbackFound" > {{generalFB_Enterpurner}}</p> 
    <div class="feedback btn33" *ngIf="feedbackFound" >
      <div *ngIf="slang == 'ENG'"><button mat-button (click)="getHome()"><mat-icon style="font-size:40px;" > </mat-icon>Exit the survey and feedback (click)</button>    </div>
      <div *ngIf="slang == 'FIN'"> <button mat-button (click)="getHome()"><mat-icon style="font-size:40px;" > </mat-icon>Poistu kyselystä ja palautteesta (klikkaa)</button>   </div>
      <div *ngIf="slang == 'SWE'"> <button mat-button (click)="getHome()"><mat-icon style="font-size:40px;" > </mat-icon>Lämna förfrågningen och respons (klicka)</button>   </div>
    </div>

    <div *ngIf="!feedbackFound" (click)="getHome()" class="btn"> www.ysimittari.lut.fi</div>
<hr style="height:2px;border-width:0;color:gray;background-color:gray">

  </div>


