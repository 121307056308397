import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../data-share.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
  infoComponentItems: any = {};
  constructor(private data: DataShareService) { }

  ngOnInit() {
    this.data.infoPageStatic.subscribe(message => this.infoComponentItems = message);
  }

}
