import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../data-share.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { WebserviceCallerService } from '../webservice-caller.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  homeComponentItems: any = {};
  surveyStatus: any = [];
  feedbackLock = true;

  constructor(public rest: WebserviceCallerService, private route: ActivatedRoute,
              private router: Router, public snackBar: MatSnackBar, private data: DataShareService) { }
  ngOnInit() {
    this.data.currentMessage.subscribe(message => this.homeComponentItems = message);
    const ufb = sessionStorage.getItem('UFB');
    if (ufb) this.feedbackLock = true ; else this.feedbackLock = false;


  }
  //
  //
  checkSurveyStatus() {
  this.rest.checkSurveyStatus().subscribe
    (
      (data: {}) => {
        this.surveyStatus = data;
        if (data === 'Active') {
           this.router.navigate(['questions']);
        } else {
           this.stopedQuestionaire();
        }
      }
      );
  }
  //
  stopedQuestionaire() {
    this.snackBar.open('Query currently not in use / Kysely ei nyt käytössä / Förfrågning inte i bruk nu', '...', {
      verticalPosition: 'bottom',
       horizontalPosition: 'center',
       panelClass: ['custom-snackbar'],
       duration : 7000
    });
  }
}


