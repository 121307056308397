import { of } from 'rxjs';
import { map, catchError, tap, retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const headers = new HttpHeaders();
headers.set('Content-Type', 'application/json; charset=utf-8');
headers.set('Accept', 'application/json');
headers.set('Access-Control-Allow-Origin', '*');
const endpoint = 'https://ysimittari.lut.fi/pbp/';
//const endpoint = 'http://localhost:5000/';
export class WebserviceCallerService {
    constructor(http) {
        this.http = http;
        // Http Options
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json'
            })
        };
    }
    extractData(res) {
        const body = res['result'];
        // console.log('Inside extractData in webservice caller');
        return body || {};
    }
    getAllLanguage() {
        // console.log('Inside getAllLanguage in webservice caller');
        return this.http.get(endpoint + 'getAllLanguage').pipe(map(this.extractData));
    }
    checkSurveyStatus() {
        // console.log('Inside checkSurveyStatus in webservice caller');
        return this.http.get(endpoint + 'checkSurveyStatus').pipe(map(this.extractData));
    }
    getAllStaticContent() {
        // console.log('Inside getAllStaticContent in webservice caller');
        return this.http.get(endpoint + 'getAllStaticContent').pipe(map(this.extractData));
    }
    getQuestionByTheme() {
        // console.log('Inside getQuestionByTheme in webservice caller');
        return this.http.get(endpoint + 'getQuestionByTheme').pipe(map(this.extractData));
    }
    getMunicipalityList() {
        // console.log('Inside getMunicipalityList in webservice caller');
        return this.http.get(endpoint + 'getMunicipalityList').pipe(map(this.extractData));
    }
    //
    getProvienceList() {
        // console.log('Inside getProvienceList in webservice caller');
        return this.http.get(endpoint + 'getProvienceList').pipe(map(this.extractData));
    }
    //
    getAllSchoolNames(request) {
        // console.log('Inside getAllSchoolNames in webservice caller');
        return this.http.get(endpoint + 'getAllSchoolNames?code=' + request.code).pipe(map(this.extractData));
    }
    //
    verifyReportKey(request) {
        return this.http.get(endpoint + 'verifyReportKey?reportKey=' + request.key).pipe(map(this.extractData));
    }
    getDownloadData(useRequest) {
        // console.log('Inside API Service for getDownloadData');
        const isoStartDate = new Date(useRequest.startDate).toISOString();
        const isoEndDate = new Date(useRequest.endDate).toISOString();
        return this.http.get(endpoint
            + 'downloadDump?startDate=' + isoStartDate
            + '&endDate=' + isoEndDate
            + '&schoolCode=' + useRequest.schoolCode
            + '&municipalityCode=' + useRequest.municipalityCode
            + '&provienceCode=' + useRequest.provienceCode
            + '&report_key=' + useRequest.report_key, { responseType: 'blob' }).pipe(retry(0), catchError(this.handleError('getDownloadData')));
    }
    //
    /*public getDownloadData(userRequest): Observable<any> {
        console.log('Inside API Service for getDownloadData');
        return this.http.post<any>(endpoint + 'downloadDump', JSON.stringify(userRequest), { responseType: 'blob' as 'json' }).pipe(
          retry(0), catchError(this.handleError<any>('getDownloadData')));
      }*/
    //
    saveUserResponse(response) {
        // console.log('Inside saveUserResponse in webservice caller with response as below : ');
        // console.log(response);
        return this.http.post(endpoint + 'saveUserResponse', JSON.stringify(response)).pipe(
        // tslint:disable-next-line:no-shadowed-variable
        tap((response) => console.log(`added new response w/ time=${response}`)), catchError(this.handleError('saveUserResponse')));
    }
    handleError(operation = 'operation', result) {
        // console.log('Inside handleError in webservice caller');
        return (error) => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead
            // TODO: better job of transforming error for user consumption
            console.log(`${operation} failed: ${error.message}`);
            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
WebserviceCallerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WebserviceCallerService_Factory() { return new WebserviceCallerService(i0.ɵɵinject(i1.HttpClient)); }, token: WebserviceCallerService, providedIn: "root" });
