import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataShareService {

  private messageSource = new BehaviorSubject('');
  private questionPage = new BehaviorSubject('');
  private infoPage = new BehaviorSubject('');
  private selectedLanguage = new BehaviorSubject('');
  //
  currentMessage = this.messageSource.asObservable();
  questionPageStatic = this.questionPage.asObservable();
  infoPageStatic = this.infoPage.asObservable();
  currentLanguage = this.selectedLanguage.asObservable();
  constructor() { }
  changeMessage(message: string) {
    this.messageSource.next(message);
  }
  changeLanguage(language: string) {
    this.selectedLanguage.next(language);
  }
  changeQuestionPageStatic(message: string) {
    this.questionPage.next(message);
  }
  changeInfoPageStatic(message: string) {
    this.infoPage.next(message);
  }
}
