import { AdminGuard } from './admin.guard';
import { AdminloginComponent } from './adminlogin/adminlogin.component';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import {QuestionsComponent} from './questions/questions.component';
import {AppexitComponent} from './appexit/appexit.component';
import {AdminComponent} from './admin/admin.component';
import {ReportComponent} from './report/report.component';
import {InfoComponent} from './info/info.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'questions', component: QuestionsComponent },
  { path: 'appexit', component: AppexitComponent },
  { path: 'admin', component: AdminComponent, canActivate: [AdminGuard] },
  { path: 'report', component: ReportComponent },
  { path: 'info', component: InfoComponent },
  { path: 'adminlogin', component: AdminloginComponent }
];

@NgModule({
  imports: [
  RouterModule.forRoot(routes),
  FormsModule,
  HttpClientModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
