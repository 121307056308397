import { HttpHeaders } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
export class AdminService {
    constructor(http, ar) {
        this.http = http;
        this.ar = ar;
        this.admin_API_URL = 'https://ysimittari.lut.fi/pbp/';
        this._rd = false;
        this.data__ = [];
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'
            })
        };
        this.getToken = () => { return localStorage.getItem('admin_token'); };
        this.getLoggedU = () => { return localStorage.getItem('admin_email'); };
        this.loggedIn = () => {
            //this.verifyLogedinU();return this._rd
            return !!localStorage.getItem('admin_token');
        };
        this.logoutU = () => {
            localStorage.removeItem('admin_token');
            localStorage.removeItem('admin_email');
            this._rd = false;
            this.ar.navigate(['/home']);
        };
    }
    // HttpClient API get() method => Fetch questions
    getQuestions() {
        return this.http.get(`${this.admin_API_URL}/showall`, this.httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    } //  Fetch feedback details
    userFeedback() {
        return this.http.get(`${this.admin_API_URL}/feedback`, this.httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }
    //  Fetch feedback details
    getFeedback() {
        return this.http.get(`${this.admin_API_URL}/showfeedback`, this.httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }
    // send email report
    emailReport(id, report) {
        return this.http.post(this.admin_API_URL + '/sendm/' + id, report, this.httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }
    // updating questions
    sendQuestion(id, updated_question) {
        return this.http.put(this.admin_API_URL + '/updatequestion/' + id, updated_question, this.httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }
    // updating feedback
    sendFeedBack(id, u_feedback) {
        return this.http.put(this.admin_API_URL + '/updatefeedback/' + id, u_feedback, this.httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }
    // verify userd data
    verifyUD(adminData, mid) {
        adminData.password = '';
        return this.http.post(this.admin_API_URL + '/verifyuser/' + mid, adminData, this.httpOptions)
            .pipe(retry(1), catchError(this.handleAdminLoginError));
    }
    handleVUError(error) {
        let errorMessage = 'unfortunately there is an error';
        if (error.error instanceof ErrorEvent) {
            //console.log('client side '+ error.error)
            errorMessage = error.message;
        }
        else {
            //console.log('server side '+ error.statusText)
            //errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            if (error.status == 500 || error.status == 401)
                localStorage.removeItem('admin_token');
            localStorage.removeItem('admin_email');
            location.reload();
        }
        // window.alert(errorMessage)
        return throwError(error.statusText);
    }
    verifyLogedinU() {
        if (this.loggedIn() && this.getLoggedU()) {
            var subject = new Subject();
            const tmp = { 'password': '', 'email': this.getLoggedU(), 'token': this.getToken() };
            this.http.post(this.admin_API_URL + '/verifyuser/3', tmp, this.httpOptions)
                .pipe(retry(1), catchError(this.handleAdminLoginError))
                .toPromise().then(data => {
                this.em = data;
                subject.next(this.em);
            });
            return subject.asObservable();
        }
        else {
            this.ar.navigate(['/adminlogin']);
        }
    }
    getTokenData() {
        if (this.loggedIn() && this.getLoggedU()) {
            const tmp = { 'password': '', 'email': this.getLoggedU(), 'token': this.getToken() };
            return this.http.post(this.admin_API_URL + '/verifyuser/2', tmp, this.httpOptions)
                .pipe(retry(1), catchError(this.handleVUError));
        }
    }
    // Error handling
    handleError(error) {
        let errorMessage = 'unfortunately there is an error';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        }
        else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            if (error.status == 500 || error.status == 401)
                localStorage.removeItem('admin_token');
            localStorage.removeItem('admin_email');
            location.reload();
        }
        // window.alert(errorMessage)
        return throwError(errorMessage);
    }
    handleAdminLoginError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // client-side error 
            errorMessage = error.message;
        }
        else {
            // Server-side error make login attempt secure
            if (error.status == 500 || error.status == 401)
                localStorage.removeItem('admin_token');
            localStorage.removeItem('admin_email');
            location.reload();
        }
        return throwError(errorMessage);
    }
}
AdminService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminService_Factory() { return new AdminService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router)); }, token: AdminService, providedIn: "root" });
