<div class="infoComponent col-md-12">

  <mat-card class="info_Card">

    <mat-card-header >
      <mat-card-title class="title">{{infoComponentItems.infoHeader}}</mat-card-title>
    </mat-card-header>

    <mat-card-content>

           <P>
                {{infoComponentItems.infoText1}}
           </P>

           <P>
                {{infoComponentItems.infoText2}}
           </P>

           <P>
                {{infoComponentItems.infoText3}}
           </P>

           <h3>
                {{infoComponentItems.infoTextEnd}}
           </h3>

    </mat-card-content>

    <mat-card-subtitle >
      <h3>
         {{infoComponentItems.infoContact1}}
      </h3>
      <h3>
         {{infoComponentItems.infoContact2}}
      </h3>
      <h3>
         {{infoComponentItems.infoContact3}}
      </h3>
      <h3>
         {{infoComponentItems.infoContact4}}
      </h3>
    </mat-card-subtitle>

  </mat-card>

  <div class="">
      <P>
         {{infoComponentItems.infoDataSecurity}}
      </P>
  </div>

</div>
