import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class DataShareService {
    constructor() {
        this.messageSource = new BehaviorSubject('');
        this.questionPage = new BehaviorSubject('');
        this.infoPage = new BehaviorSubject('');
        this.selectedLanguage = new BehaviorSubject('');
        //
        this.currentMessage = this.messageSource.asObservable();
        this.questionPageStatic = this.questionPage.asObservable();
        this.infoPageStatic = this.infoPage.asObservable();
        this.currentLanguage = this.selectedLanguage.asObservable();
    }
    changeMessage(message) {
        this.messageSource.next(message);
    }
    changeLanguage(language) {
        this.selectedLanguage.next(language);
    }
    changeQuestionPageStatic(message) {
        this.questionPage.next(message);
    }
    changeInfoPageStatic(message) {
        this.infoPage.next(message);
    }
}
DataShareService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataShareService_Factory() { return new DataShareService(); }, token: DataShareService, providedIn: "root" });
