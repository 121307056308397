import { AppexitComponent } from './../appexit/appexit.component';
import {Component, OnInit, VERSION, ViewChild, AfterViewInit, ViewChildren, QueryList, ElementRef} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormArray, FormControl, AbstractControl} from '@angular/forms';
import { WebserviceCallerService } from '../webservice-caller.service';
import { DataShareService } from '../data-share.service';
import {ActivatedRoute, Router} from '@angular/router';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';AppexitComponent
import { MatStepperModule } from '@angular/material';
import {Observable, Subscription} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {SelectItem} from 'primeng/api';
import {stringify} from 'querystring';


export interface StepType {
  label: string;
  fields: FormlyFieldConfig[];
}
interface Municipality {
  name_1: string;
  name_2: string;
  code: number;
}
interface School {
  name_eng: string;
  name_fin: string;
  name_swe: string;
  code: number | string;
}

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {
  @ViewChildren('checkboxes1', { read: ElementRef}) private checkboxes1: QueryList<ElementRef>;
  @ViewChildren('checkboxes2', { read: ElementRef}) private checkboxes2: QueryList<ElementRef>;
  // @ViewChild('checkboxes', {static: false, read: ElementRef}) private checkboxes: ElementRef;
  //
  feedbackLock = true;
  municipalities1: any = [];
  municipalities2: any = [];
  selectedMunicipality1: Municipality;
  selectedMunicipality2: Municipality;
  municipalityList1: any = {};
  municipalityList2: any = {};
  municipalityListShow1: any = [];
  municipalityListShow2: any = [];
  selectedMunicipalityToSave: any = [];
  //
  showSchoolDropdown = false;
  schoolNames: any = [];
  selectedSchoolNames: School;
  selectedSchoolToSave: any = [];
  //
  questionComponentItems: any = {};
  private ngVersion: string = VERSION.full;
  public selectedLanguage: string;
  private questionListByTheme: any = [];
  private  firstQuestionPage = '';
  private  prevQuestionPage = '';
  private  nowQuestionPage = '';
  private  nextQuestionPage = '';
  private  lastQuestionPage = '';
  private  questionListSelectedTheme: any = [];
  public  listTheme: any = [];
  private  userResponse = {
    end_time: undefined,
    user_response: undefined,
    start_time: undefined,
    user_language: undefined
  };
  private  requestBody = {
    code: undefined
  };
  //
  isSchoolRequired = false;
  activedStep = 0;
  IsChecked = false;
  answerModelCheckbox1: any = [];
  answerModelCheckbox2: any = [];
  // Only required when not passing the id in methods
  answerModel = {
    SPA: undefined,
    SPB: undefined
  };
  //
  SpaAnswer: any = [];
  SpbAnswer: any = [];
  totalQuestions = 0;
  progress = 0;
  totalAnswered = 0;
  pageQuestionLength = 0;
  progressBarValue = 0;
  questionsInPage: any = [];
  questionsAnswered: any = [];
  private answerPrev: string;
  private answerNow: string;
  private answerNowStr: string;
  private showTextBox1 = false;
  private showTextBox2 = false;
  //
  constructor(public rest: WebserviceCallerService, private route: ActivatedRoute,
              private router: Router, private data: DataShareService, private formBuilder: FormBuilder) {
    this.getMunicipalityList();
  }
   //
  ngOnInit() {
    this.data.questionPageStatic.subscribe(message => this.questionComponentItems = message);
    this.getQuestionByTheme();
    this.data.currentLanguage.subscribe(language => this.selectedLanguage = language);
    // this.getMunicipalityList();
    const ufb = sessionStorage.getItem('UFB');
    if (ufb) {this.feedbackLock = true ; this.router.navigate(['appexit'])} else this.feedbackLock = false;

  }
  //
  public showlanguage () {
    return (this.data.currentLanguage.subscribe(language => this.selectedLanguage = language));
  }

  saveUserResponse() {
    localStorage.removeItem('UR');localStorage.removeItem('UL');
    localStorage.setItem('UR',JSON.stringify(this.userResponse.user_response ))
    localStorage.setItem('UL', this.selectedLanguage)
    // console.log('Inside saveUserResponse in question component caller');
    this.rest.saveUserResponse(this.userResponse).subscribe(res => {
          // console.log(res);
          if (res.status === 'success') {
            this.router.navigate(['appexit']);
          }
        }, (err) => {
          // console.log(err);
        }); 
  }
  //
  getQuestionByTheme() {
    // console.log('Inside getQuestionByTheme in question component caller');
    this.questionListByTheme = [];
    this.rest.getQuestionByTheme().subscribe
    ((data: {}) => {
        // console.log('logging question content');
        // console.log(data);
        this.questionListByTheme = data;
        this.totalQuestions = this.questionListByTheme.length;
        // console.log('Total questions to be answered: ' + this.totalQuestions);
        this.userResponse.start_time = new Date();
        this.data.currentLanguage.subscribe(language => this.selectedLanguage = language);
        // console.log('logging question language to be shown');
        // console.log(this.selectedLanguage);
        this.useQuestionController(this.questionListByTheme);
      }
    );
  }
  //
  useQuestionController(allQuestions) {
    // console.log('¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤');
    // This function prepares the question controller after getting all fetched questions and arranging them in pages based on theme name
    for ( const eachQuestion of allQuestions) {
      // console.log(eachQuestion.mtee_question_level);
      // console.log(eachQuestion.mtee_question_id);
      // console.log(eachQuestion.mtee_question_theme_name);
      // console.log(eachQuestion.mtee_question_type_name);
      // console.log(eachQuestion.questionType);
      /*if (eachQuestion.questionType === 'Checkbox') {
        for (const optionArray of eachQuestion.options) {
          optionArray.isCheked = false;
          // console.log(optionArray);
        }
      }*/
      if (this.firstQuestionPage === '') {
        this.firstQuestionPage = eachQuestion.mtee_question_theme_name;
        // console.log('firstQuestionPage');
        // console.log(this.firstQuestionPage);
      }
      if (this.firstQuestionPage !== '' && this.prevQuestionPage === '') {
        this.prevQuestionPage = eachQuestion.mtee_question_theme_name;
        // console.log('prevQuestionPage');
        // console.log(this.prevQuestionPage);
      }
      if (this.prevQuestionPage !== '' && this.prevQuestionPage !== eachQuestion.mtee_question_theme_name) {
        this.nextQuestionPage = eachQuestion.mtee_question_theme_name;
        this.lastQuestionPage = eachQuestion.mtee_question_theme_name;
        // console.log('nextQuestionPage');
        // console.log(this.nextQuestionPage);
        this.questionsInPage.push(this.prevQuestionPage = this.questionListSelectedTheme.length);
        this.questionsAnswered.push(0);
        // console.log('questionsInPage ');
        // console.log(this.questionsInPage);
        // console.log('questionsAnswered ');
        // console.log(this.questionsAnswered);
        this.listTheme.push(this.prevQuestionPage = this.questionListSelectedTheme);
        this.questionListSelectedTheme = [];
        this.prevQuestionPage = '';
        // console.log(this.listTheme);
      }
      this.questionListSelectedTheme.push(eachQuestion);
    }
    // console.log('lastQuestionPage');
    // console.log(this.lastQuestionPage);
    // console.log(this.listTheme);
    this.listTheme.push(this.prevQuestionPage = this.questionListSelectedTheme);
    this.questionListSelectedTheme = [];
    this.prevQuestionPage = '';
    // console.log(this.listTheme);
  }
  //
  getMunicipalityList() {
    // console.log('Inside getMunicipalityList in question component caller');
    this.municipalityList1 = {};
    this.municipalityList2 = {};
    this.rest.getMunicipalityList().subscribe
    (
      (data: {}) => {
        // console.log('logging municipality data');
        // console.log(data);
        /*const temp: Municipality = {name: undefined, code: undefined};
        this.municipalities1.push(temp);
        this.municipalities2.push(temp);*/
        // tslint:disable-next-line:forin
        for (const x in data) {
          // tslint:disable-next-line:one-variable-per-declaration
          const temp1: Municipality = {name_1: undefined, name_2: undefined, code: undefined};
          const temp2: Municipality = {name_1: undefined, name_2: undefined, code: undefined};
          this.municipalityList1[data[x].mtee_municipality_name_1] = data[x].mtee_municipality_code;
          temp1.name_1 = data[x].mtee_municipality_name_1;
          temp1.name_2 = data[x].mtee_municipality_name_2;
          temp1.code = data[x].mtee_municipality_code;
          this.municipalityListShow1.push(data[x].mtee_municipality_name_1);
          this.municipalityList2[data[x].mtee_municipality_name_2] = data[x].mtee_municipality_code;
          temp2.name_1 = data[x].mtee_municipality_name_1;
          temp2.name_2 = data[x].mtee_municipality_name_2;
          temp2.code = data[x].mtee_municipality_code;
          this.municipalityListShow2.push(data[x].mtee_municipality_name_2);
          this.municipalities1.push(temp1);
          this.municipalities2.push(temp2);
        }
        this.municipalities1.sort((a1, b1) => a1.name_1.localeCompare(b1.name_1));
        this.municipalities2.sort((a2, b2) => a2.name_2.localeCompare(b2.name_2));
        // console.log(this.municipalityList1);
        // console.log(this.municipalityListShow1);
      }
      );
  }
  //
  answeredQuestionRadio(event: Event, questionId: string | number, questionAnswer: number, indexPage) {
    // console.log('Page No: ' + indexPage);
    if (this.answerModel[questionId] === undefined) {
      let answeredQuestionForPage = this.questionsAnswered[indexPage];
      answeredQuestionForPage = answeredQuestionForPage + 1;
      this.questionsAnswered[indexPage] = answeredQuestionForPage;
      // console.log('Question Answered for page: ' + this.questionsAnswered);
    }
    //
    // console.log(questionId);
    // console.log(questionAnswer);
    this.answerModel[questionId] = questionAnswer;
    // code to calculate progress bar value
    this.pageQuestionLength = 0;
    // tslint:disable-next-line:forin
    for (const x in this.answerModel) {
      if (this.answerModel[x] !== undefined) {
        if (x !== 'SPB') {
          this.pageQuestionLength = this.pageQuestionLength + 1;
        }
      }
    }
    this.progress = this.pageQuestionLength;
    this.progressBarValue = (this.progress / this.totalQuestions) * 100;
    // console.log('answerModel Length =====> ' + this.pageQuestionLength);
    // console.log(this.answerModel);
  }

  //
  answeredQuestionCheckbox( questionId: string , questionAnswer: string, isChecked, indexPage) {
    // Logic to see if the question has been answered before. if not answered updating the count.
    // console.log('Page No: ' + indexPage);
    if (this.answerModel[questionId] === undefined) {
      let answeredQuestionForPage = this.questionsAnswered[indexPage];
      answeredQuestionForPage = answeredQuestionForPage + 1;
      this.questionsAnswered[indexPage] = answeredQuestionForPage;
      // console.log('Question Answered for page: ' + this.questionsAnswered);
    }
    // Handling for first checkbox
    if (questionId === 'CBA') {
        if (isChecked) {
          this.checkboxes1.forEach((element) => {
            // console.log(element.nativeElement.classList);
            if (element.nativeElement.id === 'CBA10') {
              if (element.nativeElement.classList.contains('mat-checkbox-checked')) {
                element.nativeElement.classList.remove('mat-checkbox-checked');
                this.answerModelCheckbox1 = [];
              }
            }
          });
          this.answerModelCheckbox1.push(questionAnswer);
          // console.log(this.answerModelCheckbox1);
          this.answerModel[questionId] = undefined;
          this.answerModel[questionId] = this.answerModelCheckbox1;
        } else {
          const index = this.answerModelCheckbox1.indexOf(questionAnswer);
          this.answerModelCheckbox1.splice(index, 1);
          this.answerModel[questionId] = undefined;
          this.answerModel[questionId] = this.answerModelCheckbox1;
        }
    }
    // Handling for second checkbox
    if (questionId === 'CBB') {
        if (isChecked) {
          this.checkboxes2.forEach((element) => {
            // console.log(element.nativeElement.classList);
            if (element.nativeElement.id === 'CBB5') {
              if (element.nativeElement.classList.contains('mat-checkbox-checked')) {
                element.nativeElement.classList.remove('mat-checkbox-checked');
                this.answerModelCheckbox2 = [];
              }
            }
          });
          this.answerModelCheckbox2.push(questionAnswer);
          // console.log(this.answerModelCheckbox2);
          this.answerModel[questionId] = undefined;
          this.answerModel[questionId] = this.answerModelCheckbox2;
        } else {
          const index = this.answerModelCheckbox2.indexOf(questionAnswer);
          this.answerModelCheckbox2.splice(index, 1);
          this.answerModel[questionId] = undefined;
          this.answerModel[questionId] = this.answerModelCheckbox2;
        }
    }
    // code to calculate progress bar value
    this.pageQuestionLength = 0;
    // tslint:disable-next-line:forin
    for (const x in this.answerModel) {
      if (this.answerModel[x] !== undefined) {
        if (x !== 'SPB') {
          this.pageQuestionLength = this.pageQuestionLength + 1;
        }
      }
    }
    this.progress = this.pageQuestionLength;
    this.progressBarValue = (this.progress / this.totalQuestions) * 100;
    // console.log('answerModel Length =====> ' + this.pageQuestionLength);
    // console.log(this.answerModel);
  }
  //
  answeredQuestionCheckbox1( questionId: string , questionAnswer: string, isChecked, indexPage) {
    // Logic to see if the question has been answered before. if not answered updating the count.
    // console.log('Page No: ' + indexPage);
    if (this.answerModel[questionId] === undefined) {
      let answeredQuestionForPage = this.questionsAnswered[indexPage];
      answeredQuestionForPage = answeredQuestionForPage + 1;
      this.questionsAnswered[indexPage] = answeredQuestionForPage;
      // console.log('Question Answered for page: ' + this.questionsAnswered);
    }
    if ( questionId === 'CBA') {
      this.checkboxes1.forEach((element) => {
        // console.log(element.nativeElement.classList);
        if (element.nativeElement.id !== 'CBA10') {
                // console.log('Checking if Checked : ' + element.nativeElement.id);
                if (element.nativeElement.classList.contains('mat-checkbox-checked')) {
                  element.nativeElement.classList.remove('mat-checkbox-checked');
                  // console.log('Removed Checked');
                }
        } else {
         element.nativeElement.classList.add('mat-checkbox-checked');
        }
      });
      // console.log(this.answerModelCheckbox1);
      this.answerModelCheckbox1 = [];
      this.answerModelCheckbox1.push(questionAnswer);
      this.answerModel[questionId] = undefined;
      this.answerModel[questionId] = this.answerModelCheckbox1;
    }
    //
    if (questionId === 'CBB') {
      this.checkboxes2.forEach((element) => {
        // console.log(element.nativeElement.classList);
        if (element.nativeElement.classList.contains('mat-checkbox-checked')) {
          element.nativeElement.classList.remove('mat-checkbox-checked');
        }
      });
      this.answerModelCheckbox2 = [];
      this.answerModelCheckbox2.push(questionAnswer);
      this.answerModel[questionId] = undefined;
      this.answerModel[questionId] = this.answerModelCheckbox2;
    }
    // code to calculate progress bar value
    this.pageQuestionLength = 0;
    // tslint:disable-next-line:forin
    for (const x in this.answerModel) {
      if (this.answerModel[x] !== undefined) {
        if (x !== 'SPB') {
          this.pageQuestionLength = this.pageQuestionLength + 1;
        }
      }
    }
    this.progress = this.pageQuestionLength;
    this.progressBarValue = (this.progress / this.totalQuestions) * 100;
    // console.log('answerModel Length =====> ' + this.pageQuestionLength);
    // console.log(this.answerModel);
  }
  //
  onMunicipalitySelect(municipality, questionId, indexPage) {
    // Logic to see if the question has been answered before. if not answered updating the count.
    // console.log('Page No: ' + indexPage);
    if (this.answerModel[questionId] === undefined) {
      let answeredQuestionForPage = this.questionsAnswered[indexPage];
      answeredQuestionForPage = answeredQuestionForPage + 1;
      this.questionsAnswered[indexPage] = answeredQuestionForPage;
      // console.log('Question Answered for page: ' + this.questionsAnswered);
    }
    // console.log('---- municipality --: ', municipality.value);
    this.selectedMunicipalityToSave = municipality.value;
    const municipalityCode = this.selectedMunicipalityToSave.code;
    // console.log('---- municipality Code --: ', this.selectedMunicipalityToSave.code);
    const municipalityName = this.selectedMunicipalityToSave.name_1;
    this.SpaAnswer = [];
    this.SpaAnswer.push(municipalityCode);
    this.SpaAnswer.push(municipalityName);
    // const municipalityValue = '[' + municipalityCode + ' , ' + municipalityName + ']';
    // console.log('---- municipality values in db ----: ', this.SpaAnswer);
    this.requestBody.code = this.selectedMunicipalityToSave.code;
    if (this.answerModel[questionId] === undefined) {
      this.answerModel.SPA = this.SpaAnswer;
    } else {
      this.answerModel.SPA = undefined;
      this.answerModel.SPA = this.SpaAnswer;
    }
    this.pageQuestionLength = 0;
    // tslint:disable-next-line:forin
    for (const x in this.answerModel) {
      this.pageQuestionLength = this.pageQuestionLength + 1;
      this.progress = this.pageQuestionLength;
      this.progressBarValue = (this.progress / this.totalQuestions) * 100;
      // console.log('answerModel Length');
      // console.log(this.pageQuestionLength);
    }
    // checking if school names needs to be filled for this municipality
    this.rest.getAllSchoolNames(this.requestBody).subscribe(
      (data: {}) => {
        // console.log('logging school data');
        // console.log(data);
        this.showSchoolDropdown = false;
        this.schoolNames = [];
        // tslint:disable-next-line:forin
        for (const x in data) {
          const temp: School = {name_eng: undefined, name_fin: undefined, name_swe: undefined, code: undefined};
          temp.name_fin = data[x].mtee_school_name_FIN;
          temp.name_eng = data[x].mtee_school_name_ENG;
          temp.name_swe = data[x].mtee_school_name_SWE;
          temp.code = data[x].mtee_school_code;
          this.schoolNames.push(temp);
          this.showSchoolDropdown = true;
        }
        if (this.showSchoolDropdown === true) {
          this.questionsInPage[indexPage] = this.questionsInPage[indexPage] + 1;
        }
      }
    );
    // code to calculate progress bar value
    this.pageQuestionLength = 0;
    // tslint:disable-next-line:forin
    for (const x in this.answerModel) {
      if (this.answerModel[x] !== undefined) {
        if (x !== 'SPB') {
          this.pageQuestionLength = this.pageQuestionLength + 1;
        }
      }
    }
    this.progress = this.pageQuestionLength;
    this.progressBarValue = (this.progress / this.totalQuestions) * 100;
    // console.log('answerModel Length =====> ' + this.pageQuestionLength);
    // console.log(this.answerModel);
  }
  //
  onSelectSchool(school, indexPage) {
    // Logic to see if the question has been answered before. if not answered updating the count.
    // console.log('Page No: ' + indexPage);
    if (this.answerModel.SPB === undefined) {
      let answeredQuestionForPage = this.questionsAnswered[indexPage];
      answeredQuestionForPage = answeredQuestionForPage + 1;
      this.questionsAnswered[indexPage] = answeredQuestionForPage;
      // console.log(this.questionsInPage);
    }
    // console.log('---- school selected ----: ', school.value);
    this.selectedSchoolToSave = school.value;
    const schoolName = this.selectedSchoolToSave.name_fin;
    const schoolCode = this.selectedSchoolToSave.code;
    this.SpbAnswer = [];
    this.SpbAnswer.push(schoolCode);
    this.SpbAnswer.push(schoolName);
    // const schoolValue = '[' + schoolCode + ' , ' + schoolName + ']';
    // console.log('---- school values in db ----: ', this.SpbAnswer);
    if (this.answerModel.SPB === undefined) {
      this.answerModel.SPB = this.SpbAnswer;
    } else {
      this.answerModel.SPB = undefined;
      this.answerModel.SPB = this.SpbAnswer;
    }
    // console.log(this.answerModel);
  }
  //
  prevStep(step) {
    this.activedStep = step - 1;
  }
  //
  nextStep(step) {
    this.activedStep = step + 1;
    // console.log(this.answerModel);
    // console.log('Page submitted');
    // console.log(this.totalAnswered);
  }
  //
  submit() {
    this.userResponse.user_response = this.answerModel;
    this.userResponse.end_time = new Date();
    this.data.currentLanguage.subscribe(language => this.selectedLanguage = language);
    this.userResponse.user_language = this.selectedLanguage;
    // console.log(this.userResponse);
    this.saveUserResponse();
  }
  //
}
