<mat-card>

  <mat-card-title >
                  {{questionComponentItems.questionInstruction}}
  </mat-card-title>

  <div class="progress">
    <mat-progress-bar mode="determinate" value={{progressBarValue}}></mat-progress-bar>
    <div style="position:relative; top: -25px; justify-content: center; right: -50%; color: white ">{{ progress }}</div>
  </div>

  <mat-horizontal-stepper >

    <mat-step *ngFor="let step of listTheme; let index = index; let last = last;">
       <!--Questions on Page: {{step.length}}  | Total Answered {{progress}}  | Page Answered: {{questionsAnswered[index]}} | Total Questions: {{totalQuestions}} | Page Question :{{pageQuestionLength}}-->

      <mat-card-content *ngFor="let question of step; index as i">
                     <mat-card  class="questionPanel">
                       <h4>{{i+1}}.  {{question.mtee_question_text[selectedLanguage]}}</h4>
                       <h6>{{question.mtee_question_instruction[selectedLanguage]}}</h6>
                       <div *ngIf="question.questionType == 'Radio'">
                         <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" >
                                     <mat-radio-button mat-button  type="button" class="mat-radio-button"
                                                       *ngFor="let option of question.options ;index as j;"
                                                       [value]="question.options[j].mtee_question_option_value"
                                                       (click)="answeredQuestionRadio($event, question.mtee_question_id, question.options[j].mtee_question_option_value, index)">

                                                           {{option['mtee_question_option_text'][0][selectedLanguage]}}
                                     </mat-radio-button>
                         </mat-radio-group>

                       </div>

                       <div *ngIf="question.questionType == 'Checkbox' &&  question.mtee_question_id == 'CBA'">

                           <button mat-button type="button" class="mat-radio-button" *ngFor="let option of question.options ;index as k;">
                                 <mat-checkbox *ngIf="k <= 9 &&  question.mtee_question_id == 'CBA'" [checked]="question.options[k].checked == true"
                                               (change)= "answeredQuestionCheckbox(question.mtee_question_id, question.options[k].mtee_question_option_value, $event.checked, index)"
                                               name="{{question.mtee_question_id}}{{k}}" id="{{question.mtee_question_id}}{{k}}" #checkboxes1>
                                                       {{option['mtee_question_option_text'][0][selectedLanguage]}}
                                 </mat-checkbox>
                                 <mat-checkbox *ngIf="k == 10 &&  question.mtee_question_id == 'CBA'" [checked]="question.options[k].checked == true"
                                               (change)= "answeredQuestionCheckbox1(question.mtee_question_id, question.options[k].mtee_question_option_value, $event.checked, index)"
                                               name="{{question.mtee_question_id}}{{k}}" id="{{question.mtee_question_id}}{{10}}" #checkboxes1>
                                                       {{option['mtee_question_option_text'][0][selectedLanguage]}}
                                 </mat-checkbox>
                           </button>
                           <!--<pre *ngIf="question.mtee_question_id == 'CBA'">{{ answerModelCheckbox1 | json}}</pre>-->
                       </div>

                       <div *ngIf="question.questionType == 'Checkbox' &&  question.mtee_question_id == 'CBB'">
                         <button mat-button type="button" class="mat-radio-button" *ngFor="let option of question.options ;index as l;">
                             <mat-checkbox *ngIf="l <= 4 &&  question.mtee_question_id == 'CBB'" [checked]="question.options[l].isChecked == true"
                                               (change)= "answeredQuestionCheckbox(question.mtee_question_id, question.options[l].mtee_question_option_value, $event.checked, index)"
                                                name="{{question.mtee_question_id}}{{l}}" id="{{question.mtee_question_id}}{{l}}" #checkboxes2>
                                                       {{option['mtee_question_option_text'][0][selectedLanguage]}}
                             </mat-checkbox>
                             <mat-checkbox *ngIf="l == 5 &&  question.mtee_question_id == 'CBB'" [checked]="question.options[l].isChecked == true"
                                               (change)= "answeredQuestionCheckbox1(question.mtee_question_id, question.options[l].mtee_question_option_value, $event.checked, index)"
                                               name="{{question.mtee_question_id}}{{l}}" id="{{question.mtee_question_id}}{{5}}" #checkboxes2>
                                                       {{option['mtee_question_option_text'][0][selectedLanguage]}}
                             </mat-checkbox>
                         </button>
                         <!--<pre *ngIf="question.mtee_question_id == 'CBB'">{{ answerModelCheckbox2 | json}}</pre>-->
                       </div>

                       <div *ngIf="question.questionType == 'Special'" class="row">

                         <div class="col-md-6">
                              <p-dropdown (onChange)="onMunicipalitySelect($event, 'SPA', index)" [options]="municipalities1"
                                          filter="true" filterMatchMode="startsWith" placeholder="- - - - - - - -"
                                     [(ngModel)]="selectedMunicipality1" optionLabel="name_1" [showClear]="false" *ngIf="selectedLanguage !== 'SWE'">
                              </p-dropdown>
                              &nbsp;&nbsp;&nbsp;
                              <p-dropdown (onChange)="onSelectSchool($event, index)" [options]="schoolNames" filter="true" filterMatchMode="startsWith" placeholder="- - - - - - - -" required
                                     [(ngModel)]="selectedSchoolNames" optionLabel="name_eng" [showClear]="false" *ngIf="selectedLanguage === 'ENG' && showSchoolDropdown === true">
                              </p-dropdown>
                              <p-dropdown (onChange)="onSelectSchool($event, index)" [options]="schoolNames"  filter="true" filterMatchMode="startsWith" placeholder="- - - - - - - -" required
                                     [(ngModel)]="selectedSchoolNames" optionLabel="name_fin" [showClear]="false" *ngIf="selectedLanguage === 'FIN' && showSchoolDropdown === true" >
                              </p-dropdown>

                              <p-dropdown (onChange)="onMunicipalitySelect($event, 'SPA', index)" [options]="municipalities2"
                                          filter="true" filterMatchMode="startsWith" placeholder="- - - - - - - -"
                                     [(ngModel)]="selectedMunicipality2" optionLabel="name_2" [showClear]="false" *ngIf="selectedLanguage === 'SWE'" >
                              </p-dropdown>
                              &nbsp;&nbsp;&nbsp;
                              <p-dropdown (onChange)="onSelectSchool($event, index)" [options]="schoolNames"  filter="true" filterMatchMode="startsWith" placeholder="- - - - - - - -" required
                                     [(ngModel)]="selectedSchoolNames" optionLabel="name_swe" [showClear]="false" *ngIf="selectedLanguage === 'SWE' && showSchoolDropdown === true" >
                              </p-dropdown>
                         </div>
                       </div>

                     </mat-card>
      </mat-card-content>

      <div>
        <button mat-button mat-raised-button type="button" color="accent" class ="left" *ngIf="index !== 0" matStepperPrevious (click)="prevStep(index)">{{questionComponentItems.backButton}}</button>
        <button mat-button mat-raised-button type="button" color="accent" class ="right"  [disabled]="(questionsInPage[index] != questionsAnswered[index])" *ngIf="!last" matStepperNext (click)="nextStep(index)">{{questionComponentItems.nextButton}}</button>
        <button mat-button mat-raised-button type="button" color="warm" class ="submit"  [disabled]="(progress != totalQuestions) || (questionsInPage[0] != questionsAnswered[0])" *ngIf="last && !feedbackLock" (click)="submit()">{{questionComponentItems.submitButton}}</button>
      </div>

    </mat-step>

  </mat-horizontal-stepper>

</mat-card>



