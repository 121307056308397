import { CanActivate, Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./admin.service";
export class AdminGuard {
    constructor(_router, adminApi) {
        this._router = _router;
        this.adminApi = adminApi;
        this.data__ = '';
    }
    canActivate() {
        //return !!this.adminApi.loggedIn()
        if (this.adminApi.getToken() && this.adminApi.getLoggedU()) {
            this.adminApi.verifyLogedinU().subscribe((r) => this.data__ = r);
            if (this.data__ != 'fail') {
                return true;
            }
            else {
                this._router.navigate(['/adminlogin']);
                return false;
            }
        }
        else {
            this._router.navigate(['/adminlogin']);
            return false;
        }
    }
}
AdminGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminGuard_Factory() { return new AdminGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AdminService)); }, token: AdminGuard, providedIn: "root" });
function setRdata() {
    this.adminApi.verifyLogedinU().subscribe((r) => this.data__ = r);
    console.log(this.data__);
}
