<div class="appExitComponent" fxLayout="row" fxLayoutAlign="center initial">
  <div class="mat-card">
      <mat-expansion-panel  [expanded]="this.isPanelExpanded" [disabled]="this.isPanelDisabled== true">

        <mat-expansion-panel-header>
          <div class="col-md-12 reportKey">

            <mat-form-field class="keyField">
              <mat-label>Enter report key to generate report.</mat-label>
              <input matInput type="text" [(ngModel)]="reportKey">
            </mat-form-field>

            <button button mat-stroked-button color="primary" class="btn-sm" (click) = "verifyKey()">
                               Verify
            </button>

          </div>
        </mat-expansion-panel-header>

        <mat-card>
          <form [formGroup]="formReport">

                          <div class="formReport" id="formReport">



                                 <div class="">
                                        <mat-form-field>
                                          <mat-label>Start date</mat-label>
                                          <input matInput [min]="minStartDate" [max]="maxStartDate" [matDatepicker]="picker1" (dateChange)="selectedStartDate($event)">
                                          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                          <mat-datepicker touchUi="true" #picker1></mat-datepicker>
                                        </mat-form-field>
                                 </div>

                                 <div class="">
                                        <mat-form-field>
                                          <mat-label>End date</mat-label>
                                          <input matInput [min]="minEndDate" [max]="maxEndDate" [matDatepicker]="picker2" (dateChange)="selectedEndDate($event)">
                                          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                          <mat-datepicker touchUi="true" #picker2></mat-datepicker>
                                        </mat-form-field>
                                 </div>

                                 <div class="">
                                        <mat-form-field>
                                          <mat-label>Provience</mat-label>
                                          <mat-select (selectionChange)="onProvienceSelect($event)" placeholder=""[(value)]="selectedProvience">
                                            <mat-option *ngFor="let provience of provienceList" [value]="provience">
                                              {{provience}}
                                            </mat-option>
                                          </mat-select>
                                        </mat-form-field>
                                 </div>

                                 <div class="">
                                        <mat-form-field>
                                          <mat-label>Municipality</mat-label>
                                          <mat-select (selectionChange)="onMunicipalitySelect($event)" placeholder=""[(value)]="selectedMunicipality1">
                                            <mat-option *ngFor="let municipality of municipalityListShow1" [value]="municipality">
                                              {{municipality}}
                                            </mat-option>
                                          </mat-select>
                                        </mat-form-field>
                                 </div>


                          </div>

          </form>

          <button mat-button mat-raised-button color="accent" class="btn-block btn-outline-dark mat-button" (click) = "downloadData()">
                               Download
          </button>
          <p class = 'errorMessage'>{{this.errorMsg}}</p>

        </mat-card>

      </mat-expansion-panel>
  </div>
</div>

