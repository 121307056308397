import {Component, OnInit, ViewChildren, QueryList, ElementRef, Output, EventEmitter} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { WebserviceCallerService } from '../webservice-caller.service';
import { DataShareService } from '../data-share.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker, MatDatepickerInputEvent} from '@angular/material/datepicker';
import * as fileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import { HttpResponse } from '@angular/common/http';
import {stringify} from 'querystring';

export interface StepType {
  label: string;
  fields: FormlyFieldConfig[];
}
interface Municipality {
  name_1: string;
  name_2: string;
  code: number;
}
interface School {
  name_eng: string;
  name_fin: string;
  name_swe: string;
  code: number | string;
}


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  startDate =  new Date();
  endDate = new Date();
  formReport: FormGroup;
  errorMsg = '';
  reportDate = new Date();
  reportKey: 'ajesh12k';
  //
  public keyVerificationRequest = {
    key: ''
  };
  //
  public formReportRequest = {
    startDate: new Date(),
    endDate: new Date(),
    schoolCode: '',
    municipalityCode: '',
    provienceCode: '',
    report_key: 'ajesh12k'
  };
  //
  minStartDate: Date;
  minEndDate: Date;
  maxStartDate: Date;
  maxEndDate: Date;
  //
  //
  municipalities1: any = [];
  municipalities2: any = [];
  selectedMunicipality1: Municipality;
  selectedMunicipality2: Municipality;
  municipalityList1: any = {};
  municipalityList2: any = {};
  municipalityListShow1: any = [];
  municipalityListShow2: any = [];
  selectedMunicipalityToSave: any = [];
  provienceList: any = [];
  provienceRecord: any = [];
  selectedProvience: any;
  //
  showSchoolDropdown = false;
  schoolNames: any = [];
  selectedSchoolNames: School;
  selectedSchoolToSave: any = [];
  //
  isPanelDisabled: boolean;
  isPanelExpanded: boolean;
  //
  selectedStartDate(event: MatDatepickerInputEvent<Date>) {
    this.startDate = new Date(event.value);
    this.endDate.setHours(0, 0, 0 );
    console.log('---------------- : ' + this.startDate);
    this.formReportRequest.startDate = this.startDate;
  }
  //
  selectedEndDate(event: MatDatepickerInputEvent<Date>) {
    this.endDate = new Date(event.value);
    this.endDate.setHours(23, 59, 59 );
    console.log('---------------- : ' + this.endDate);
    this.formReportRequest.endDate = this.endDate;
  }
  //
  constructor(private formBuilder: FormBuilder, public rest: WebserviceCallerService, private router: Router,
              private dataBus: DataShareService) {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentDate = new Date().getDay();
    this.minStartDate = new Date(2020, 0, 1);
    this.maxStartDate = new Date();
    this.minEndDate = new Date(2020, 0, 1);
    this.maxEndDate = new Date();
  }

  ngOnInit() {
    this.formReport = this.formBuilder.group({
      reportKey: [null, Validators.required]
    });
    this.formReportRequest.report_key = this.reportKey;
    this.getMunicipalityList();
    this.getProvienceList();
    this.isPanelDisabled = true;
    this.isPanelExpanded = false;
  }
  //
  getProvienceList() {
    this.rest.getProvienceList().subscribe(
      (data: {}) => {
        this.provienceRecord = data;
        // tslint:disable-next-line:forin
        for (const x in data) {
          this.provienceList.push(data[x]);
        }
      }
    );
  }
  //
  getMunicipalityList() {
    // console.log('Inside getMunicipalityList in question component caller');
    this.municipalityList1 = {};
    this.municipalityList2 = {};
    this.rest.getMunicipalityList().subscribe
    (
      (data: {}) => {
        // tslint:disable-next-line:forin
        for (const x in data) {
          // tslint:disable-next-line:one-variable-per-declaration
          const temp1: Municipality = {name_1: undefined, name_2: undefined, code: undefined};
          this.municipalityList1[data[x].mtee_municipality_name_1] = data[x].mtee_municipality_code;
          temp1.name_1 = data[x].mtee_municipality_name_1;
          temp1.name_2 = data[x].mtee_county_provience_name_1;
          temp1.code = data[x].mtee_municipality_code;
          this.municipalityListShow1.push(data[x].mtee_municipality_name_1);
          this.municipalities1.push(temp1);
        }
      }
      );
    console.log('Municipality');
    this.municipalityListShow1.sort((a1, b1) => a1.localeCompare(b1));
    console.log(this.municipalityListShow1);
  }
  //
  onMunicipalitySelect(municipality) {
    console.log('---- Municipality ----: ', municipality.value);
    this.formReportRequest.municipalityCode = municipality.value;
    console.log('---- Municipality in form ----: ', this.formReportRequest.municipalityCode);
  }
  //
  onProvienceSelect(provience) {
    this.selectedProvience = provience.value;
    this.municipalityListShow1 = [];
    // tslint:disable-next-line:forin
    for (const x in this.municipalities1) {
      const listedProvience = this.municipalities1[x].name_2;
      if (listedProvience === this.selectedProvience) {
        console.log('Provience matched');
        this.municipalityListShow1.push(this.municipalities1[x].name_1);
      }
    }
    console.log(this.municipalityListShow1);
    console.log('Provience Value Sent');
    console.log(this.selectedProvience);
    this.formReportRequest.provienceCode = this.selectedProvience;
  }
  //
  downloadData() {
    // console.log('Inside downloadData');
    this.formReportRequest.report_key = this.reportKey;
    console.log(this.formReportRequest);
    this.rest.getDownloadData(this.formReportRequest)
      .subscribe(response => {
        console.log('Inside Download File after response');
        const fileName = 'Ysimittari_' + stringify(this.startDate) + '_' + stringify(this.endDate) + '.csv';
        fileSaver.saveAs(response, fileName);
        console.log('File Downloaded');
        this.isPanelDisabled = true;
        this.isPanelExpanded = false;
      });
  }
  //
  verifyKey() {
    this.keyVerificationRequest.key = this.reportKey;
    this.rest.verifyReportKey(this.keyVerificationRequest).subscribe((data: {}) => {
      if (this.reportKey === data) {
        this.isPanelDisabled = false;
        this.isPanelExpanded = true;

      }
    });
  }
}
