<div class="homeComponent" >

  <mat-card class="home_Card">

    <mat-card-header >
      <mat-card-title *ngIf="!feedbackLock" class="title">{{homeComponentItems.instruction}}</mat-card-title>
    </mat-card-header>

    <mat-card-content >

           <h3 *ngIf="!feedbackLock">
                {{homeComponentItems.usermessage}}
           </h3>
<h1 *ngIf="feedbackLock"> Kiitos!!</h1>
    </mat-card-content>

    <mat-card-subtitle >
      <h3>
         {{homeComponentItems.contact}}
      </h3>
    </mat-card-subtitle>
    <mat-card-actions class="action-buttons">
      <!--<button  routerLink="/questions" routerLinkActive="active" mat-button><mat-icon>thumb_up_alt</mat-icon> {{homeComponentItems.startbutton}}</button>-->
      <button *ngIf="!feedbackLock" mat-button (click)="checkSurveyStatus()"><mat-icon>thumb_up_alt</mat-icon> {{homeComponentItems.startbutton}}</button>
    </mat-card-actions>

  </mat-card>
</div>
