import { Injector } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { AdminService } from './admin.service';
import * as i0 from "@angular/core";
export class TokenInterceptorService {
    constructor(inj) {
        this.inj = inj;
    }
    intercept(req, next) {
        let authservice = this.inj.get(AdminService);
        let tokenizedReq = req.clone({
            setHeaders: {
                Authorization: `Bearer ${authservice.getToken()}`
            }
        });
        return next.handle(tokenizedReq);
    }
}
TokenInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TokenInterceptorService_Factory() { return new TokenInterceptorService(i0.ɵɵinject(i0.INJECTOR)); }, token: TokenInterceptorService, providedIn: "root" });
