import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'QurPipe',
  pure: false,

})
export class QurPipe implements PipeTransform {

  transform(value, searchText: string)  {
    if ( value.length === 0 || searchText === ''){
      return value;
    }
    const f_text = []; 
    for (let i = 0; i < value.length; i++) {
      if  (value[i]['mtee_question_text']['ENG'] === searchText) {
            f_text.push({
              '_id':{ '$oid':value[i]['_id']['$oid']
              },
              mtee_question_text: {
                ENG : value[i]['mtee_question_text']['ENG'],
                SWE : value[i]['mtee_question_text']['SWE'],
                FIN : value[i]['mtee_question_text']['FIN']
              },
              mtee_question_instruction: {
                ENG : value[i]['mtee_question_instruction']['ENG'],
                SWE : value[i]['mtee_question_instruction']['SWE'],
                FIN : value[i]['mtee_question_instruction']['FIN']
              }});
            }
    }
   return f_text;
   
} 
}
