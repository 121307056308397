import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AdminService } from './admin.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private inj : Injector) { }
  intercept(req, next){
    let authservice = this.inj.get(AdminService)
    let tokenizedReq = req.clone({
        setHeaders: {
        Authorization: `Bearer ${authservice.getToken()}`
        }
      })
      return next.handle(tokenizedReq)
  }
}
