import { map } from 'rxjs/operators';
import { feedback } from './../feedback';
import { Questions } from './../Questions';
import { QurPipe } from './../qur.pipe';
import { FormsModule } from '@angular/forms';
import { QuestionsComponent } from './../questions/questions.component';
import { HttpClient, JsonpClientBackend } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from '../admin.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { Subject } from 'rxjs';
import {Component, OnInit} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { WebserviceCallerService } from '../webservice-caller.service';
import { DataShareService } from '../data-share.service';
import { Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import * as fileSaver from 'file-saver';
import {stringify} from 'querystring';
import * as XL from 'xlsx';



export interface StepType {
  label: string;
  fields: FormlyFieldConfig[];
}
interface Municipality {
  name_1: string;
  name_2: string;
  code: number;
}
interface School {
  name_eng: string;
  name_fin: string;
  name_swe: string;
  code: number | string;
}


declare var $:any; 

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})


export class AdminComponent implements OnInit {

public EditMode = false;
public FB_EditMode = false;
       TargetQuestion = 99;
       TargetFB = 99;
       info_text : string = "";
       fb_lang : string = "";
public Questions: any = [];
public feedback: any = [];
public e_workbook: any = [];
public modifiedWorkbook: any = [];
public final_arry: any = [];
public searchText : number  = 10;
public qq : number  = 50;
public u_q_fin : string  = "";
public u_q_swe : string  = "";
public u_q_eng : string  = "";
        Adminlinks = [
            { title: 'One', fragment: 'one' },
            { title: 'Two', fragment: 'two' }
          ];
 public selectedLanguage: string;
 startDate =  new Date();
  endDate = new Date();
  formReport: FormGroup;
  errorMsg = '';
  reportDate = new Date();
  //
  public keyVerificationRequest = {
    key: '' 
  };
  //
  public formReportRequest = {
    startDate: new Date(),
    endDate: new Date(),
    schoolCode: '',
    municipalityCode: '',
    provienceCode: '',
    report_key: ''
  };
  public summary = {
    County: '',
    Municipality: '',
    results: 0,
    sdate:'',
    edate: ''
  };
  //
  minStartDate: Date;
  minEndDate: Date;
  maxStartDate: Date;
  maxEndDate: Date;
  //
  //
  municipalities1: any = [];
  municipalities2: any = [];
  selectedMunicipality1: Municipality;
  selectedMunicipality2: Municipality;
  municipalityList1: any = {};
  municipalityList2: any = {};
  municipalityListShow1: any = [];
  municipalityListShow2: any = [];
  selectedMunicipalityToSave: any = [];
  provienceList: any = [];
  provienceRecord: any = [];
  selectedProvience: any;
  //
  showSchoolDropdown = false;
  schoolNames: any = [];
  selectedSchoolNames: School;
  selectedSchoolToSave: any = [];
  //
  isPanelDisabled: boolean;
  isPanelExpanded: boolean;
  arrayBuffer: any;
  //
  selectedStartDate(event: MatDatepickerInputEvent<Date>) {
    this.startDate = new Date(event.value);
    this.endDate.setHours(0, 0, 0 );
    //console.log('---------------- : ' + this.startDate);
    this.formReportRequest.startDate = this.startDate;
  }
  //
  selectedEndDate(event: MatDatepickerInputEvent<Date>) {
    this.endDate = new Date(event.value);
    this.endDate.setHours(23, 59, 59 );
   // console.log('---------------- : ' + this.endDate);
    this.formReportRequest.endDate = this.endDate;
  }
  public chartDataG: any;
  public chartDataL: any;
  public countyOnly: any;
  public countySelected: string = ''
  public summaryReport: boolean = false;
  public downloadXL: any;
 

  constructor(public rest: WebserviceCallerService, private formBuilder: FormBuilder, public adminApi : AdminService, public adminRoute: ActivatedRoute,private sharedata: DataShareService, private router: Router) {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentDate = new Date().getDay();
    this.minStartDate = new Date(2020, 0, 1);
    this.maxStartDate = new Date();
    this.minEndDate = new Date(2020, 0, 1);
    this.maxEndDate = new Date();
// charts data
this.chartDataG = { }; // gender chart
this.chartDataL = { }; // language chart

	  }

  ngOnInit() { 
   $.LoadingOverlay("show");
     this.loadQuestions(); this.loadFeedback()
        $(document).ready(function(){
          actTab('questionmanagment');
        });
  function actTab(tab){
  let act =  $('.nav-pills a[href="#' + tab + '"]');
  act.tab('show'); act.addClass('active');
  };
 

  this.formReport = this.formBuilder.group({
    reportKey: [null, Validators.required]
  });
  //this.formReportRequest.report_key = this.reportKey;
  this.getMunicipalityList();
  this.getProvienceList();
 
  $.LoadingOverlay("hide");document.getElementById('pageMenu').style.visibility = "visible";
  this.getLang()

  }

  submitQuestion(){
  }

  loadQuestions() { $('*').find('span.info2').text('');
  return this.adminApi.getQuestions().subscribe((data: {}) => {
    this.Questions = data; this.getLang() })
  }

  editFB(f){
    this.FB_EditMode=true; this.TargetFB = f;
  }

  loadFeedback() {
  this.adminApi.userFeedback().subscribe((fbdata: {}) => {
    this.feedback = fbdata;
    })
  }

  getLang = () => {return (this.sharedata.currentLanguage.subscribe(language => { this.selectedLanguage = language;
    if (language == "ENG"){
      this.fb_lang = 'English';
    }
    if (language == 'SWE'){
      this.fb_lang = 'Swedish';
    }
    if (language == 'FIN'){
      this.fb_lang = 'Finnish';
    } else {
      this.fb_lang = 'Finnish'
    }  }));}
  loadQ = () => {$('*').find('span.info2').text('loading Questions...'); this.loadQuestions()}
  cancleUD = (a) => {this.EditMode=false; this.TargetQuestion = 99;$('#'+ a).find('span.info1').text('Update cancled.');document.getElementById('pageMenu').style.visibility = "visible";$('*').find('span.info2').text('')}
  cancleFB = () => {this.FB_EditMode=false; this.TargetFB = 99;}
  editData = (a) => { this.EditMode=true; this.TargetQuestion = a; $($($('#' + a).children()[2]).children()[0]).autofocus; updateQestion(a, this.searchText)}
  saveData = (a, b: string) => { this.EditMode=false; this.TargetQuestion = 99;
      $('#'+ a).find('span.info1').text('Saving question please wait...'); $('*').find('span.info2').text('')
      //let btt = $('*').find('#buttons')[a];
      //let ev = $($($('#' + a).children()[6]).children()[1]).val();
      let ev = $('#' + a).find('#q_eng').val();let ins_ev = $('#' + a).find('#ins_eng').val();
      let sv = $('#' + a).find('#q_swe').val();let ins_sv = $('#' + a).find('#ins_swe').val();
      let fv = $('#' + a).find('#q_fin').val();let ins_fv = $('#' + a).find('#ins_fin').val();
      let bb = b;
    var ary = {
      "updated_question": {
        "ENG": ev,
        "FIN": fv,
        "SWE": sv,
        "INS_ENG": ins_ev,
        "INS_FIN": ins_fv,
        "INS_SWE": ins_sv
              }
      }
      var dta = JSON.stringify(ary)
      if(window.confirm('Are you sure, you want to update question ' + (a+1) +'?')){
        $.LoadingOverlay("show", {
        background:"rgba(125, 170, 110, 0.5)",text:"Updating Question..."
        }); $.LoadingOverlay("show", { background:"" , image : "", });
        this.adminApi.sendQuestion(bb, dta).subscribe(ndata => {
          if (typeof(ndata) == 'string') {
            $('#'+ a).find('span.info1').text(ndata); $($($('#' + a).children()[2]).children()[0]).autofocus;
            $.LoadingOverlay("hide", true) ;
              } else {
                  if (typeof(ndata['mtee_question_instruction']) == 'object'){ this.qq = (a+(this.searchText - 10));
                    this.Questions[this.qq].mtee_question_text = ndata['mtee_question_text']; this.Questions[this.qq].mtee_question_instruction = ndata['mtee_question_instruction'];
                      $('#'+ a).find('span.info1').text('Question is Updated');
                      $.LoadingOverlay("hide", true); $('#' + a).find('#q_fin').autofocus;
                  }
              }
        })
    } else { // Cancled update
     $('#'+ a).find('span.info1').text('Update is cancled');
   }
   document.getElementById('pageMenu').style.visibility = "visible";
  }
  saveFB = (_f, b: string) => { this.FB_EditMode=false; this.TargetFB = 99;
    $('*').find('span.info3').text('Saving feedback please wait...')
    let l_f_ev = $('*').find('#fb_en.form-control.0').val();let m_f_ev = $('*').find('#fb_en.form-control.1').val();let h_f_ev = $('*').find('#fb_en.form-control.2').val();
    let l_f_sv = $('*').find('#fb_sw.form-control.0').val();let m_f_sv = $('*').find('#fb_sw.form-control.1').val();let h_f_sv = $('*').find('#fb_sw.form-control.2').val();
    let l_f_fv = $('*').find('#fb_fn.form-control.0').val();let m_f_fv = $('*').find('#fb_fn.form-control.1').val();let h_f_fv = $('*').find('#fb_fn.form-control.2').val();
    let bb = b;
  var ary = {
    "updated_feedback": {
      "ENG_L": l_f_ev, "FIN_L": l_f_fv, "SWE_L": l_f_sv,
      "ENG_M": m_f_ev, "FIN_M": m_f_fv, "SWE_M": m_f_sv,
      "ENG_H": h_f_ev, "FIN_H": h_f_fv, "SWE_H": h_f_sv
            }
    }
    var dta = JSON.stringify(ary);
     $.LoadingOverlay("show", {
      background:"rgba(105, 70, 110, 0.5)",text:"Updating Feedback..."
      }); $.LoadingOverlay("show", { background:"" , image : "", });
      this.adminApi.sendFeedBack(bb, dta).subscribe(_ndata => {
        this.loadFeedback();this.FB_EditMode=false; this.TargetFB = 99;
        $.LoadingOverlay("hide", true); 
        })
  }
    public displayMsg = (x: number, y: string) => {$('#'+ x).find('span.info1').text(y);}
    

    getProvienceList() {
      this.rest.getProvienceList().subscribe(
        (data: {}) => {
          this.provienceRecord = data;
          // tslint:disable-next-line:forin
          let tmparr  = [];
          for (const x in data) {
            tmparr.push(data[x]);
          }
          this.provienceList = tmparr.sort((a,b)=>{
            if (a.toLowerCase() > b.toLowerCase()) {return 1}
            if (a.toLowerCase() <b.toLowerCase()) {return -1}
             return 0
          });
        }
      );
    }

    getMunicipalityList() {
     this.municipalityList1 = {};
      this.municipalityList2 = {};
      this.rest.getMunicipalityList().subscribe
      (
        (data: {}) => {
          let tmparr  = [];
          // tslint:disable-next-line:forin
          for (const x in data) {
            // tslint:disable-next-line:one-variable-per-declaration
            const temp1: Municipality = {name_1: undefined, name_2: undefined, code: undefined};
            this.municipalityList1[data[x].mtee_municipality_name_1] = data[x].mtee_municipality_code;
            temp1.name_1 = data[x].mtee_municipality_name_1;
            temp1.name_2 = data[x].mtee_county_provience_name_1;
            temp1.code = data[x].mtee_municipality_code;
            tmparr.push(data[x].mtee_municipality_name_1);
            this.municipalities1.sort()
            this.municipalities1.push(temp1);
          }
          this.municipalityListShow1 = tmparr.sort((c,d)=>{
            if (c.toLowerCase() > d.toLowerCase()) return 1
            if (c.toLowerCase() < d.toLowerCase()) return -1
            return 0
          })
        }
        );
    //  console.log('Municipality');
    //  this.municipalityListShow1.sort((a1, b1) => a1.localeCompare(b1));
    //  console.log(this.municipalityListShow1);
    }
    onMunicipalitySelect(municipality) {
      this.resetChart() ;this.errorMsg = ''
      // console.log('---- Municipality ----: ', municipality.value);
       this.formReportRequest.municipalityCode = municipality.value;
     //  console.log('---- Municipality in form ----: ', this.formReportRequest.municipalityCode);
     }
  resetChart() {
    this.chartDataG = { }; this.chartDataL = { };  
  }
     onProvienceSelect(provience) {
      this.resetChart();this.errorMsg = ''
       this.formReportRequest.municipalityCode = '';
      this.selectedProvience = provience.value;
      this.municipalityListShow1 = [];
      // tslint:disable-next-line:forin
      for (const x in this.municipalities1) {
        const listedProvience = this.municipalities1[x].name_2;
        if (listedProvience === this.selectedProvience) {
         // console.log('Provience matched');
          this.municipalityListShow1.push(this.municipalities1[x].name_1);
        }
      }
    //  console.log(this.municipalityListShow1); 
    //  console.log('Provience Value Sent');
   //   console.log(this.selectedProvience);
      this.formReportRequest.provienceCode = this.selectedProvience;
    }
    downloadData() {
      $.LoadingOverlay("show", {
        background:"rgba(125, 170, 110, 0.5)",text:"Downloading..."
        });      
        
    //  if ($('*').find('#createChart').is(':checked')) this.summaryReport = true; else this.summaryReport = false;
    this.summaryReport = true;
    let county = this.formReportRequest.provienceCode
      let mun = this.formReportRequest.municipalityCode
      let countyCode = 0;
      this.errorMsg = 'loading ... '+ mun +' of '+county; if ($('*').find('#sumOnly').is(':checked')) this.errorMsg = 'loading Summary of ... '+ mun +' of '+county;
      this.resetChart()
      //reseting
        this.formReportRequest.municipalityCode = '';
        this.formReportRequest.provienceCode = '';
        this.formReportRequest.report_key = 'ajesh12k';
        if (county != ''){ 
        if (county == 'Ahvenanmaa') countyCode = 19;
        if (county == 'Etelä-Karjala') countyCode = 8;
        if (county == 'Etelä-Pohjanmaa') countyCode = 13;
        if (county == 'Etelä-Savo') countyCode = 9;
        if (county == 'Kainuu') countyCode = 17;
        if (county == 'Kanta-Häme') countyCode = 4;
        if (county == 'Keski-Pohjanmaa') countyCode = 15;
        if (county == 'Keski-Suomi') countyCode = 12;
        if (county == 'Kymenlaakso') countyCode = 7;
        if (county == 'Lappi') countyCode = 18;
        if (county == 'Pirkanmaa') countyCode = 5;
        if (county == 'Pohjanmaa') countyCode = 14;
        if (county == 'Pohjois-Karjala') countyCode = 11;
        if (county == 'Pohjois-Pohjanmaa') countyCode = 16;
        if (county == 'Pohjois-Savo') countyCode = 10;
        if (county == 'Päijät-Häme') countyCode = 6;
        if (county == 'Satakunta') countyCode = 3;
        if (county == 'Uusimaa') countyCode = 1;
        if (county == 'Varsinais-Suomi') countyCode = 2;
        }
            // get records
          this.rest.getDownloadData(this.formReportRequest)
          .subscribe(response => {        
            let fileReader = new FileReader();    
            fileReader.readAsArrayBuffer(response);     
            fileReader.onload = (e) => {
                this.arrayBuffer = fileReader.result;    
                var data = new Uint8Array(this.arrayBuffer);    
                var arr = new Array();    
                for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);    
                var bstr = arr.join("");    
                var workbook = XL.read(bstr, {type:"binary"});    
                var first_sheet_name = workbook.SheetNames[0];    
                var worksheet = workbook.Sheets[first_sheet_name];    
              //  console.log(XL.utils.sheet_to_json(worksheet,{raw:true}));    
                 let arr1 = XL.utils.sheet_to_json(worksheet,{raw:true});
              // apply fixture 
              var result = applyFix(arr1); 
              let result_t = [];
              // sorting on what option is selected mun only or all or county
              let sOption = 0; 
              this.errorMsg = 'Filtring '+ result.length+' results...'
              if(county != 'all' && mun != 'all' && mun != ''){
                 result_t = result.filter(function(obj) {
                  return obj["AG: Q3B. Municipality name"] == mun; 
            });
            sOption = 2; // muncipality only
            this.generateCharts(result_t, sOption, county, mun,)
              }
              if (county == 'all' && mun == 'all'){
                 result_t = arr1; 
                 sOption = 3;   // all finland   
              this.generateCharts(result_t, sOption, county, mun,)
              }
              if (county != 'all' && county != '' && mun == 'all'){
                 result_t = result.filter(function(obj) {
                    return obj["AD: County code"] == countyCode; 
                  });
                  sOption = 1; // all muincipalities of proviences 
              this.generateCharts(result, sOption, county, mun) 
              }
              // summary only 
              // reset report language check boxs      
              let lOption=1 ; // english              
        if ($('*').find('#rlSwe').is(':checked')) {
          lOption = 2; // swedish
          $('*').find('#rlFin').prop( "checked", false );}
        if ($('*').find('#rlFin').is(':checked')) {
          lOption = 3; // finnish
          $('*').find('#rlSwe').prop( "checked", false );} 

              if ($('*').find('#sumOnly').is(':checked')){ 
                if (result_t.length != 0) {
              result_t = this.summaryResult(result_t, sOption,lOption)
                }
            }
              // export to excel  
          if (result_t.length != 0) {
            this.errorMsg = '' 
           if ($('*').find('#downloadXL').is(':checked')) {              
            const worksheet2: XL.WorkSheet = XL.utils.json_to_sheet(result_t);
            const workbook2: XL.WorkBook = { Sheets: { 'data': worksheet2 }, SheetNames: ['data'] };
            const excelBuffer: any = XL.write(workbook2, { bookType: 'xlsx', type: 'array' });
            const data_: Blob = new Blob([excelBuffer], { type: 'xlsx' });
            let aa:string = this.formReportRequest.startDate.toISOString().split('T')[0];this.summary.sdate = aa;
            let bb:string = this.formReportRequest.endDate.toISOString().split('T')[0];this.summary.edate = bb;
            const fileName2 = 'YM_' + aa + '_to_' + bb + '.xlsx';          
            fileSaver.saveAs(data_, fileName2);             
            } else {
              const worksheet2: XL.WorkSheet = XL.utils.json_to_sheet(result_t);
              const workbook2: XL.WorkBook = { Sheets: { 'data': worksheet2 }, SheetNames: ['data'] };
              const excelBuffer: any = XL.write(workbook2, { bookType: 'csv', type: 'array' });
              const data_: Blob = new Blob([excelBuffer], { type: 'csv' });
              let aa:string = this.formReportRequest.startDate.toISOString().split('T')[0];this.summary.sdate = aa;
              let bb:string = this.formReportRequest.endDate.toISOString().split('T')[0];this.summary.edate = bb;
              const fileName2 = 'YM_'+ aa + '_to_' + bb + '.csv';          
              fileSaver.saveAs(data_, fileName2); 
            }              
          } else {this.errorMsg= 'No result found'}             
            }   
          }); 
          // switch back data 
          this.formReportRequest.municipalityCode = mun;
          this.formReportRequest.provienceCode = county;
      $.LoadingOverlay("hide", true); 
     }
  displayChart(result: any) {
              let g_data = []; let l_data = []
              for (const val_ in result){
               for (const k of Object.keys(result[val_])){
              //  console.log(k + '... '+ result[val_][k])                 
                if (k == 'AK: Q1. Gender 1.woman 2.man 3.prefer not answer'){
                  g_data.push(result[val_][k])
                }
                if (k == 'AJ: Language. 1. FIN  2. SWE  3. ENG '){
                  l_data.push(result[val_][k])
                }
               }
             }  
             this.changeDataGender(countArry(1, g_data),countArry(2, g_data),countArry(3, g_data),'Female', 'Male', 'Others')
             this.changeDataLanguage(countArry(1, l_data),countArry(2, l_data),countArry(3, l_data),'Finnish','Swedish','English')
             // modify wbook
            this.modifiedWorkbook = result.map(
               obj => {
                 return {
                     "County" : obj["AE: County name"],
                     "Municipality": obj["AG: Q3B. Municipality name"],
                     "Language": obj["AJ: Language. 1. FIN  2. SWE  3. ENG "],
                     "We have a lot of co-operation with companies at our school 1-5": obj["BK: Q11. We have a lot of co-operation with companies at our school 1-5"]
                 }
             });
  }
      summaryResult(result: any[], op, lo) {  
        let _op = op, m_code = 0, c_code =0, m_name = '', c_name = '', reportLang = lo;
        let q1_t = [], q2_t = [], q3_t = [], q4_1_t = [],q4_2_t = [],q4_3_t = [],q4_4_t = [],q4_5_t = [],q4_6_t = [],q4_7_t = [],q4_8_t = [],q4_9_t = [],q4_10_t = [],q4_11_t = [], q5_t = [], q6_t = [], q7_t = [], q8_t = [], q9_t = [], q10_t = [], q11_t = [], q12_t = [], q13_t = [], q14_t = [], q15_t = [];
        let q16_t = [], q17_t = [], q18_t = [], q19_t = [], q20_t = [], q21_t = [], q22_t = [], q23_t = [], q24_t = [], q25_t = [], q26_t = [], q27_t = [], q28_t = [], q29_t = [],q30_t = [],q31_t = [],q32_t = [],q33_t = [],q34_t = [],q35_t = [], q7_1_t = [], q7_2_t = [], q7_3_t = [], q7_4_t = [], q7_5_t = [], q7_6_t = [];
        var result_o: any = []; let g_d = [];let l_d = [];
        for (const val_ in result){
          for (const k of Object.keys(result[val_])){
      //    console.log(k + '... '+ result[val_][k])
        if (k == 'AF: Q3A. Municipality code'){
          m_code = result[val_][k]
        }
        if (k == 'AD: County code'){
        c_code = result[val_][k]
        }
        if (k == 'AG: Q3B. Municipality name'){
          m_name = result[val_][k]
        }
        if (k == 'AE: County name'){
          c_name = result[val_][k]
        }
        if (k == 'AK: Q1. Gender 1.woman 2.man 3.prefer not answer'){
          g_d.push(result[val_][k])
        }
        if (k == 'AJ: Language. 1. FIN  2. SWE  3. ENG '){
          l_d.push(result[val_][k])
        }
        if (k == 'AL: Q2. Future  1.vocat. 2.highschool 3.10th grade 4. other'){
          q2_t.push(result[val_][k])
        }
        if (k == 'AN: Q4.1. Hobby / Music (0=no,1=yes)'){
          q4_1_t.push(result[val_][k])
        }
        if (k == 'AO: Q4.2.Hobby / Sports'){
          q4_2_t.push(result[val_][k])
        }
        if (k == 'AP: Q4.3. Hobby / Pets'){
          q4_3_t.push(result[val_][k])
        }
        if (k == 'AQ: Q4.4. Hobby / Movies'){
          q4_4_t.push(result[val_][k])
        }
        if (k == 'AR: Q4.5. Hobby / Drawing'){
          q4_5_t.push(result[val_][k])
        }
        if (k == 'AS: Q4.6. Hobby / Reading'){
          q4_6_t.push(result[val_][k])
        }
        if (k == 'AT: Q4.7. Hobby / E-games'){
          q4_7_t.push(result[val_][k])
        }
        if (k == 'AU: Q4.8. Hobby / Blog'){
          q4_8_t.push(result[val_][k])
        }
        if (k == 'AV: Q4.9. Hobby / Organization'){
          q4_9_t.push(result[val_][k])
        }
        if (k == 'AW: Q4.10. Hobby / Other'){
          q4_10_t.push(result[val_][k])
        }
        if (k == 'AX: Q4.11. No hobbies'){
          q4_11_t.push(result[val_][k])
        }
        if (k == 'AY: Q5. Grade / language, value 4-10'){
          q5_t.push(result[val_][k])          
        } 
        if (k == 'AZ: Q6. Grade / language, value 4-10'){
          q6_t.push(result[val_][k])          
        }
        if (k == 'BB: Q7.1. Enterprise family / Mother (value 0=no, value 1=yes)'){
          q7_1_t.push(result[val_][k])          
        }
        if (k == 'BC: Q7.2. Enterprise family / Father'){
          q7_2_t.push(result[val_][k])          
        }
        if (k == 'BD: Q7.3. Enterprise family / Grandparent'){
          q7_3_t.push(result[val_][k])          
        }
        if (k == 'BE: Q7.4. Enterprise family / Sibling'){
          q7_4_t.push(result[val_][k])          
        }
        if (k == 'BF: Q7.5. Enterprise family / Other close one'){
          q7_5_t.push(result[val_][k])          
        }
        if (k == 'BG: Q7.6. Enterprise family / No family or close ones'){
          q7_6_t.push(result[val_][k])          
        } 
        if (k == "BH: Q8. YrityskylÃ¤  1.yes  2.no  3. don't know"){
          q8_t.push(result[val_][k])          
        }
        if (k == "BI: Q9. NY 1.yes  2.no  3. don't know"){
          q9_t.push(result[val_][k])          
        }
        if (k == 'BJ: Q10. School has offered me adequate information on eShip 1-5'){
          q10_t.push(result[val_][k])          
        }
        if (k == 'BK: Q11. We have a lot of co-operation with companies at our school 1-5'){
          q11_t.push(result[val_][k])          
        }
        if (k == 'BL: Q12. Company co-operation is very useful to me 1-5'){
          q12_t.push(result[val_][k])          
        }
        if (k == 'BM: Q13. We often discuss issues related to entrepreneurship at school 1-5'){
          q13_t.push(result[val_][k])          
        }
        if (k == 'BN: Q14. We often discuss issues related to entrepreneurship at home. 1-5'){
          q14_t.push(result[val_][k])          
        }
        if (k == 'BO: Q15. We often discuss issues related to entrepreneurship with friends 1-5'){
          q15_t.push(result[val_][k])          
        }
        if (k == 'BP: Q16. I have set myself goals related to school and/or hobbies 1-5'){
          q16_t.push(result[val_][k])          
        }
        if (k == 'BQ: Q17. I know that I am able to complete difficult projects and/or tasks 1-5'){
          q17_t.push(result[val_][k])          
        }
        if (k == 'BR: Q18. I know where I am good at 1-5'){
          q18_t.push(result[val_][k])
        }
        if (k == 'BS: Q19. I have faith in myself 1-5'){
          q19_t.push(result[val_][k])
        }
        if (k == 'BT: Q20. I am persistent 1-5'){
          q20_t.push(result[val_][k])
        }
        if (k == 'BU: Q21. I am creative 1-5'){
          q21_t.push(result[val_][k])
        }
        if (k == 'BV: Q22. I want to do things better than others 1-5'){
          q22_t.push(result[val_][k])
        }
        if (k == 'BW: Q23. I work well under pressure 1-5'){
          q23_t.push(result[val_][k])
        }
        if (k == 'BX: Q24. I can affect my life 1-5'){
          q24_t.push(result[val_][k])
        }
        if (k == 'BY: Q25. I am the one in my circle of friends who has new ideas 1-5'){
          q25_t.push(result[val_][k])
        }
        if (k == 'BZ: Q26.  When I try out something new I am not afraid to take risks 1-5'){
          q26_t.push(result[val_][k])
        }
        if (k == 'CA: Q27. I avoid situations where I do not know what to do reversed 1-5'){
          q27_t.push(result[val_][k])
        }
        if (k == 'CB: Q28. I keep my goals even when I face obstacles 1-5'){
          q28_t.push(result[val_][k])
        }
        if (k == 'CC: Q29. I am able to change my actions flexibly in changing situations. 1-5'){
          q29_t.push(result[val_][k])
        }
        if (k == 'CD: Q30. I think I could work as an entrepreneur 1-5'){
          q30_t.push(result[val_][k])
        }
        if (k == 'CE: Q31. It would be great if I could become an entrepreneur 1-5'){
          q31_t.push(result[val_][k])
        }
        if (k == 'CF: Q32. I understand how the economy works in an enterprise 1-5'){
          q32_t.push(result[val_][k])
        }
        if (k == 'CG: Q33. I am able to plan a project 1-5'){
          q33_t.push(result[val_][k])
        }
        if (k == 'CH: Q34. I am able to draw a business plan 1-5'){
          q34_t.push(result[val_][k])
        }
        if (k == 'CI: Q35. I want to establish a company in the future 1-5'){
          q35_t.push(result[val_][k])
        } 
          }
           
        }
     /*   console.log( q16_t.length + '..' +q17_t.length + '..' +q18_t.length + '..' +q19_t.length + '..' +q20_t.length + '..' +q21_t.length + '..' +q22_t.length + '..' +q23_t.length + '..' +q24_t.length + '..' +q25_t.length + '..' +q26_t.length + '..' +q27_t.length + '..' +q28_t.length + '..' +q29_t.length )
        console.log('average =' + this.arAverage(q16_t).toFixed(0))
        console.log('count =' + countArry(1, q16_t))
       */ 
      //reportLang = 1 eng, 2 swedish , 3 finnish
     // if (reportLang = 1) { // report language is english
     if (!$('*').find('#rlFin').is(':checked') && !$('*').find('#rlSwe').is(':checked')) {
        // processing new array for english report
    // _op 1 = county, 2 = municipality, 3= finland    
        result_o.push({ // row 1 and 2
          'Start Date':this.formReportRequest.startDate.toISOString().split('T')[0],
          'End Date':this.formReportRequest.endDate.toISOString().split('T')[0],
          ..._op ==1 && {'County Code':c_code},
          ..._op ==1 && {'County Name':c_name},

          ..._op ==2 && {'Municipality Code':m_code},
          ..._op ==2 && {'Municipality Name':m_name},
          ..._op ==2 && {'Municipality group':'NA'},
          ..._op ==2 && {'County Code':c_code},
          ..._op ==2 && {'County Name':c_name},

        'Number of responses':this.summary.results,        
        'Languages':' ',
        'Genders':' ',
        ' ':"Data : ",
        '2.Future':'2.Where do you primarily apply after primary school?',             
  /*    'Q4.1. Hobby / Music (0=no,1=yes)':((countArry(1,q4_1_t) / q4_1_t.length)*100 ).toFixed(2) + ' %',
        'Q4.2. Hobby / Sports':((countArry(1,q4_2_t) / q4_2_t.length)*100 ).toFixed(2) + ' %',
        'Q4.3. Hobby / Pets':((countArry(1,q4_3_t) / q4_3_t.length)*100 ).toFixed(2) + ' %',
        'Q4.4. Hobby / Movies':((countArry(1,q4_4_t) / q4_4_t.length)*100 ).toFixed(2) + ' %',
        'Q4.5. Hobby / Drawing':((countArry(1,q4_5_t) / q4_5_t.length)*100 ).toFixed(2) + ' %',
        'Q4.6. Hobby / Reading':((countArry(1,q4_6_t) / q4_6_t.length)*100 ).toFixed(2) + ' %',
        'Q4.7. Hobby / E-games':((countArry(1,q4_7_t) / q4_7_t.length)*100 ).toFixed(2) + ' %',
        'Q4.8. Hobby / Blog':((countArry(1,q4_8_t) / q4_8_t.length)*100 ).toFixed(2) + ' %',
        'Q4.9. Hobby / Organization':((countArry(1,q4_9_t) / q4_9_t.length)*100 ).toFixed(2) + ' %',
        'Q4.10. Hobby / Other':((countArry(1,q4_10_t) / q4_10_t.length)*100 ).toFixed(2) + ' %',
        'Q4.11. No hobbies':((countArry(1,q4_11_t) / q4_11_t.length)*100 ).toFixed(2) + ' %', */
        '4.1 Hobby':'Which of the following hobbies you are doing regularly? value Yes / No',
        '4.2 Hobby':'Which of the following hobbies you are doing regularly? value Yes / No',
        '4.3 Hobby':'Which of the following hobbies you are doing regularly? value Yes / No',
        '4.4 Hobby':'Which of the following hobbies you are doing regularly? value Yes / No',
        '4.5 Hobby':'Which of the following hobbies you are doing regularly? value Yes / No',
        '4.6 Hobby':'Which of the following hobbies you are doing regularly? value Yes / No',
        '4.7 Hobby':'Which of the following hobbies you are doing regularly? value Yes / No',
        '4.8 Hobby':'Which of the following hobbies you are doing regularly? value Yes / No',
        '4.9 Hobby':'Which of the following hobbies you are doing regularly? value Yes / No',
        '4.10 Hobby':'Which of the following hobbies you are doing regularly? value Yes / No',
        '4.11 Hobby':'Which of the following hobbies you are doing regularly? value Yes / No',
        '5.Grade Mother tongue':'Your latest grade in mother tongue? value 4-10',
        '6.Grade Mathematics':'Your latest grade in mathematics? value 4-10',
        '7.1 Family':'7.Are there entrepreneurs in your family? value Yes / No',
        '7.2 Family':'7.Are there entrepreneurs in your family? value Yes / No',
        '7.3 Family':'7.Are there entrepreneurs in your family? value Yes / No',
        '7.4 Family':'7.Are there entrepreneurs in your family? value Yes / No',
        '7.5 Family':'7.Are there entrepreneurs in your family? value Yes / No',
        '7.6 Family':'7.Are there entrepreneurs in your family? value Yes / No',
        '8.Yrityskylä':'8.Have you visited Yrityskylä learning environment?',
        '9.JA etc.':'9.Have you taken part in Junior Achiever activity (e.g. JA Company program, Little Entrepreneurs)?',
        '10.E Information':'10.School has offered me adequate information on eShip 1-5',
        '11.Co-operation':'11.We have a lot of co-operation with companies at our school 1-5',
        '12.+Co-operation':'12.Company co-operation is very useful to me ',
        '13.Discuss 1 School':'13.We often discuss issues related to entrepreneurship at school 1-5',
        '14.Discuss 2 Home':'14.We often discuss issues related to entrepreneurship at home. 1-5',
        '15.Discuss 3 Friends':'15.We often discuss issues related to entrepreneurship with friends 1-5',
        '16.Goal':'16.I have set myself goals related to school and/or hobbies 1-5',
        '17.+Project':'17.I know that I am able to complete difficult projects and/or tasks 1-5',
        '18.Good at':'18.I know where I am good at 1-5',
        '19.Faith in myself':'19.I have faith in myself 1-5',
        '20.Persistent':'20.I am persistent 1-5',
        '21.Creative':'21.I am creative 1-5',
        '22.Better':'22.I want to do things better than others 1-5',
        '23.Pressure':'23.I work well under pressure 1-5',
        '24.Affecting':'24.I can affect my life 1-5',
        '25.Ideas':'25.I am the one in my circle of friends who has new ideas 1-5',
        '26.Take risks':'26.When I try out something new I am not afraid to take risks 1-5',
        '27.Avoiding':'27.I avoid situations where I do not know what to do reversed 1-5',
        '28.Obstacles':'28.I keep my goals even when I face obstacles 1-5',
        '29.Flexibility':'29.I am able to change my actions flexibly in changing situations. 1-5',
        '30.Entrepreneur?':'30.I think I could work as an entrepreneur 1-5',
        '31.+Entrepreneur':'31.It would be great if I could become an entrepreneur 1-5',
        '32.E economy':'32.I understand how the economy works in an enterprise 1-5',
        '33.+Project':'33.I am able to plan a project 1-5',
        '34.Business plan':'34.I am able to draw a business plan 1-5',
        '35.Company':'I want to establish a company in the future 1-5'
        },{
        '2.Future':'Vocational('+countArry(1, q2_t)+')',
        '4.1 Hobby':'Music',
        '4.2 Hobby':'Sports',
        '4.3 Hobby':'Pets',
        '4.4 Hobby':'Movies',
        '4.5 Hobby':'Drawing',
        '4.6 Hobby':'Reading',
        '4.7 Hobby':'E-games',
        '4.8 Hobby':'Blog',
        '4.9 Hobby':'Organization',
        '4.10 Hobby':'Other',
        '4.11 Hobby':'No Hobbies',
        '7.1 Family':'Mother',
        '7.2 Family':'Father',
        '7.3 Family':'Grandparent',
        '7.4 Family':'Sibling',
        '7.5 Family':'Other person close to me',
        '7.6 Family':'No entrepreneurs in my family',        
        '8.Yrityskylä':'Yes('+countArry(1, q8_t)+')',
        '9.JA etc.':'Yes('+countArry(1, q9_t)+')',
        '10.E Information':this.arAverage(q10_t).toFixed(2),
        '11.Co-operation':this.arAverage(q11_t).toFixed(2),
        '12.+Co-operation':this.arAverage(q12_t).toFixed(2),
        '13.Discuss 1 School':this.arAverage(q13_t).toFixed(2),
        '14.Discuss 2 Home':this.arAverage(q14_t).toFixed(2),
        '15.Discuss 3 Friends':this.arAverage(q15_t).toFixed(2),
        '16.Goal':this.arAverage(q16_t).toFixed(2),
        '17.+Project':this.arAverage(q17_t).toFixed(2),
        '18.Good at':this.arAverage(q18_t).toFixed(2),
        '19.Faith in myself':this.arAverage(q19_t).toFixed(2),
        '20.Persistent':this.arAverage(q20_t).toFixed(2),
        '21.Creative':this.arAverage(q21_t).toFixed(2),
        '22.Better':this.arAverage(q22_t).toFixed(2),
        '23.Pressure':this.arAverage(q23_t).toFixed(2),
        '24.Affecting':this.arAverage(q24_t).toFixed(2),
        '25.Ideas':this.arAverage(q25_t).toFixed(2),
        '26.Take risks':this.arAverage(q26_t).toFixed(2),
        '27.Avoiding':this.arAverage(q27_t).toFixed(2),
        '28.Obstacles':this.arAverage(q28_t).toFixed(2),
        '29.Flexibility':this.arAverage(q29_t).toFixed(2),
        '30.Entrepreneur?':this.arAverage(q30_t).toFixed(2),
        '31.+Entrepreneur':this.arAverage(q31_t).toFixed(2),
        '32.E economy':this.arAverage(q32_t).toFixed(2),
        '33.+Project':this.arAverage(q33_t).toFixed(2),
        '34.Business plan':this.arAverage(q34_t).toFixed(2),
        '35.Company':this.arAverage(q35_t).toFixed(2),
             
       /*   },{
          ' ':"--------",
         'Q16. I have set myself goals related to school and/or hobbies 1-5':aveToFeedback(this.arAverage(q16_t).toFixed(0)),
          'Q17. I know that I am able to complete difficult projects and/or tasks 1-5':aveToFeedback(this.arAverage(q17_t).toFixed(0)),
          'Q29. I am able to change my actions flexibly in changing situations. 1-5':aveToFeedback(this.arAverage(q29_t).toFixed(0)),
          */
          })
           // row 4
          result_o.push({           
            'Languages':'English(' +countArry(3, l_d) +')',
            'Genders':'Male('+countArry(2, g_d)+')',
            '2.Future':'Highschool('+countArry(2, q2_t)+')',
            '4.1 Hobby':countArry(1,q4_1_t),
            '4.2 Hobby':countArry(1,q4_2_t),
            '4.3 Hobby':countArry(1,q4_3_t),
            '4.4 Hobby':countArry(1,q4_4_t),
            '4.5 Hobby':countArry(1,q4_5_t),
            '4.6 Hobby':countArry(1,q4_6_t),
            '4.7 Hobby':countArry(1,q4_7_t),
            '4.8 Hobby':countArry(1,q4_8_t),
            '4.9 Hobby':countArry(1,q4_9_t),
            '4.10 Hobby':countArry(1,q4_10_t),
            '4.11 Hobby':countArry(1,q4_11_t),
            '5.Grade Mother tongue':this.arAverage(q5_t).toFixed(2),
            '6.Grade Mathematics':this.arAverage(q6_t).toFixed(2),
          /*  ..._op ==2 && {'Q4.1. Hobby / Music (0=no,1=yes)':countArry(1,q4_1_t) + '/' + q4_1_t.length},
            ..._op ==2 && {'Q4.2. Hobby / Sports':countArry(1,q4_2_t) + '/' + q4_2_t.length},
            ..._op ==2 && {'Q4.3. Hobby / Pets':countArry(1,q4_3_t) + '/' + q4_3_t.length},
            ..._op ==2 && {'Q4.4. Hobby / Movies':countArry(1,q4_4_t) + '/' + q4_4_t.length},
            ..._op ==2 && {'Q4.5. Hobby / Drawing':countArry(1,q4_5_t) + '/' + q4_5_t.length},
            ..._op ==2 && {'Q4.6. Hobby / Reading':countArry(1,q4_6_t) + '/' + q4_6_t.length},
            ..._op ==2 && {'Q4.7. Hobby / E-games':countArry(1,q4_7_t) + '/' + q4_7_t.length},
            ..._op ==2 && {'Q4.8. Hobby / Blog':countArry(1,q4_8_t) + '/' + q4_8_t.length},
            ..._op ==2 && {'Q4.9. Hobby / Organization':countArry(1,q4_9_t) + '/' + q4_9_t.length},
            ..._op ==2 && {'Q4.10. Hobby / Other':countArry(1,q4_10_t) + '/' + q4_10_t.length},
            ..._op ==2 && {'Q4.11. No hobbies':countArry(1,q4_11_t) + '/' + q4_11_t.length}, */
            '7.1 Family':((countArry(1,q7_1_t) / q7_1_t.length)*100 ).toFixed(2) + ' %',
            '7.2 Family':((countArry(1,q7_2_t) / q7_2_t.length)*100 ).toFixed(2) + ' %',
            '7.3 Family':((countArry(1,q7_3_t) / q7_3_t.length)*100 ).toFixed(2) + ' %',
            '7.4 Family':((countArry(1,q7_4_t) / q7_4_t.length)*100 ).toFixed(2) + ' %',
            '7.5 Family':((countArry(1,q7_5_t) / q7_5_t.length)*100 ).toFixed(2) + ' %',
            '7.6 Family':((countArry(1,q7_6_t) / q7_6_t.length)*100 ).toFixed(2) + ' %',
            '8.Yrityskylä':'No('+countArry(2, q8_t)+')',
            '9.JA etc.':'No('+countArry(2, q9_t)+')'}),
            // row 5
          result_o.push({            
            'Languages':'Swedish(' +countArry(2, l_d) +')',
            'Genders':'Female('+countArry(1, g_d)+')',
            '2.Future':'10th grade('+countArry(3, q2_t)+')',
            '8.Yrityskylä':'Dont know('+countArry(3, q8_t)+')',
            '9.JA etc.':'Dont know('+countArry(3, q9_t)+')'})
          
            // row 6
            result_o.push({
              'Languages':'Finnish(' +countArry(1, l_d) +')',            
              'Genders':'Others(' + countArry(3, g_d)+ ')',
              '2.Future':'Others('+countArry(4, q2_t)+')'})
                // row 7 last row
            result_o.push({
              ..._op ==1 && {' ':'---English--- Report--- County-----'},
              ..._op ==2 && {' ':'---English--- Report--- Muincipality-----'},
              ..._op ==3 && {' ':'---English--- Report--- Finalnd-----'}})
            
            }
       // if (reportLang = 2){ // report language swedish
       if ($('*').find('#rlSwe').is(':checked')) {
              // processing new array for Swedish report
              result_o.push({
                'Startdatum':this.formReportRequest.startDate.toISOString().split('T')[0],
                'Slutdatum':this.formReportRequest.endDate.toISOString().split('T')[0],
                ..._op ==1 && {'Landskap Nummer':c_code},
                ..._op ==1 && {'Landskap':c_name},
      
                ..._op ==2 && {'Kommun Nummer':m_code},
                ..._op ==2 && {'Kommun':m_name},
                ..._op ==2 && {'Kommungrupp':' '},
                ..._op ==2 && {'Landskap Nummer':c_code},
                ..._op ==2 && {'Landskap':c_name},
      
              'Antal svar':this.summary.results,        
              'Språk':' ',
              'Kön':' ',
              ' ':"Data : ",
              'Q2.Framtid':'Vilket studiealternativ är ditt första val i studieansökan efter nionde klass?',
              '4.1 Aktivitet':'Vilka av följande aktiviteter gör du utanför skolan regelbundet? Ja / Nej',
              '4.2 Aktivitet':'Vilka av följande aktiviteter gör du utanför skolan regelbundet? Ja / Nej',
              '4.3 Aktivitet':'Vilka av följande aktiviteter gör du utanför skolan regelbundet? Ja / Nej',
              '4.4 Aktivitet':'Vilka av följande aktiviteter gör du utanför skolan regelbundet? Ja / Nej',
              '4.5 Aktivitet':'Vilka av följande aktiviteter gör du utanför skolan regelbundet? Ja / Nej',
              '4.6 Aktivitet':'Vilka av följande aktiviteter gör du utanför skolan regelbundet? Ja / Nej',
              '4.7 Aktivitet':'Vilka av följande aktiviteter gör du utanför skolan regelbundet? Ja / Nej',
              '4.8 Aktivitet':'Vilka av följande aktiviteter gör du utanför skolan regelbundet? Ja / Nej',
              '4.9 Aktivitet':'Vilka av följande aktiviteter gör du utanför skolan regelbundet? Ja / Nej',
              '4.10 Aktivitet':'Vilka av följande aktiviteter gör du utanför skolan regelbundet? Ja / Nej',
              '4.11 Aktivitet':'Vilka av följande aktiviteter gör du utanför skolan regelbundet? Ja / Nej',
              '5.Modersmål':'Vad var ditt senaste vitsord i modersmål?  4-10',
              '6.Matemat':'Vad var ditt senaste vitsord i matematik?  4-10',
              '7.1 Familj':'7.Finns det företagare i din familj? Ja / Nej',
              '7.2 Familj':'7.Finns det företagare i din familj? Ja / Nej',
              '7.3 Familj':'7.Finns det företagare i din familj? Ja / Nej',
              '7.4 Familj':'7.Finns det företagare i din familj? Ja / Nej',
              '7.5 Familj':'7.Finns det företagare i din familj? Ja / Nej',
              '7.6 Familj':'7.Finns det företagare i din familj? Ja / Nej',
              '8.Företagarbyn':'8.Har du besökt Företagarbyn?',
              '9.UF osv.':'9.Har du varit med i Ung Företagsamhets (UF) verksamhet (t.ex. UF Ett år som företagare, Miniföretagarna)?',
              '10.F Kunskap':'10.Skolan har erbjudit mig tillräckligt med kunskaper om företagsamhet 1-5',
              '11.Samarbete':'11.Vi samarbetar mycket med företag i vår skola 1-5',
              '12.+Samarbete':'12.Jag har nytta av det samarbete som görs med företag 1-5 ',
              '13.Samtal1 Skola':'13.Vi diskuterar ofta företagsamhet i skolan 1-5',
              '14.Samtal2 Hemma':'14.Vi diskuterar ofta företagsamhet hemma 1-5',
              '15.Samtal3 Kompisar':'15.Vi diskuterar ofta företagsamhet med kompisar 1-5',
              '16.Mål':'16.Jag har satt upp mål för mina studier eller hobbyer 1-5',
              '17.+Projekt':'17.Jag vet att jag klarar även svårare projekt och/eller uppgifter 1-5',
              '18.Bra på':'18.Jag vet vilka saker jag är bra på 1-5',
              '19.Tror på mig':'19.Jag tror på mig själv 1-5',
              '20.Kvarhållande':'20.Jag ger inte upp lätt',
              '21.Kreativ':'21.Jag är kreativ',
              '22.Bättre':'22.Jag vill utföra uppgifter bättre än andra  ',
              '23.Stress':'23.Jag arbetar bra under stress',
              '24.Påverka':'24.Jag kan påverka mitt eget liv',
              '25.Idéer':'25.I vår umgängeskrets är det oftast jag som hittar på nya idéer',
              '26.Ta risker':'26.Jag kan ta risker när jag testar nya saker',
              '27.Undvika':'27.Jag undviker situationer då jag inte vet vad som ska göras',
              '28.Motgångar':'28.Jag arbetar mot mitt mål trots motgångar',
              '29.Flexibel':'29.Jag är flexibel och ändrar mitt agerande i föränderliga situationer',
              '30.Företagare?':'30.Jag skulle kunna arbeta som företagare',
              '31.+Företagare':'31.Det skulle vara fint om jag blev företagare',
              '32.F ekonomi':'32.Jag förstår hur företagsekonomi fungerar',
              '33.+Projekt':'33.Jag kan planera ett projekt',
              '34.Affärsplan ':'34.Jag kan göra en affärsplan',
              '35.Företag':'35.Jag vill grunda ett företag i framtiden'
              },{
              'Q2.Framtid':'Yrkesskola('+countArry(1, q2_t)+')',
              '4.1 Aktivitet':'Musik',
              '4.2 Aktivitet':'Motion',
              '4.3 Aktivitet':'Sällskapsdjur',
              '4.4 Aktivitet':'Bio, konserter',
              '4.5 Aktivitet':'Kunst (Teckning, målning, fotografering)',
              '4.6 Aktivitet':'Läsning',
              '4.7 Aktivitet':'E-spel (Datorspel eller konsolspel)',
              '4.8 Aktivitet':'Blog-Vlog-Video',
              '4.9 Aktivitet':'Föreninsverksamhet (4H, Scouting osv.)',
              '4.10 Aktivitet':'Annat',
              '4.11 Aktivitet':'Ingen sysselsättning',
              '7.1 Familj':'Mor',
              '7.2 Familj':'Far',
              '7.3 Familj':'Mor- eller farföräldrer',
              '7.4 Familj':'Syskon',
              '7.5 Familj':'Annan närstående person or bekant',
              '7.6 Familj':'Ingen företagare i min familj eller närstående',        
              '8.Företagarbyn':'Ja('+countArry(1, q8_t)+')',
              '9.UF osv.':'Ja('+countArry(1, q9_t)+')',
              '10.F Kunskap':this.arAverage(q10_t).toFixed(2),
              '11.Samarbete':this.arAverage(q11_t).toFixed(2),
              '12.+Samarbete':this.arAverage(q12_t).toFixed(2),
              '13.Samtal1 Skola':this.arAverage(q13_t).toFixed(2),
              '14.Samtal2 Hemma':this.arAverage(q14_t).toFixed(2),
              '15.Samtal3 Kompisar':this.arAverage(q15_t).toFixed(2),
              '16.Mål':this.arAverage(q16_t).toFixed(2),
              '17.+Projekt':this.arAverage(q17_t).toFixed(2),
              '18.Bra på':this.arAverage(q18_t).toFixed(2),
              '19.Tror på mig':this.arAverage(q19_t).toFixed(2),
              '20.Kvarhållande':this.arAverage(q20_t).toFixed(2),
              '21.Kreativ':this.arAverage(q21_t).toFixed(2),
              '22.Bättre':this.arAverage(q22_t).toFixed(2),
              '23.Stress':this.arAverage(q23_t).toFixed(2),
              '24.Påverka':this.arAverage(q24_t).toFixed(2),
              '25.Idéer':this.arAverage(q25_t).toFixed(2),
              '26.Ta risker':this.arAverage(q26_t).toFixed(2),
              '27.Undvika':this.arAverage(q27_t).toFixed(2),
              '28.Motgångar':this.arAverage(q28_t).toFixed(2),
              '29.Flexibel':this.arAverage(q29_t).toFixed(2),
              '30.Företagare?':this.arAverage(q30_t).toFixed(2),
              '31.+Företagare':this.arAverage(q31_t).toFixed(2),
              '32.F ekonomi':this.arAverage(q32_t).toFixed(2),
              '33.+Projekt':this.arAverage(q33_t).toFixed(2),
              '34.Affärsplan ':this.arAverage(q34_t).toFixed(2),
              '35.Företag':this.arAverage(q35_t).toFixed(2),
                  
       /*   },{
          ' ':"--------",
         'Q16. I have set myself goals related to school and/or hobbies 1-5':aveToFeedback(this.arAverage(q16_t).toFixed(0)),
          'Q17. I know that I am able to complete difficult projects and/or tasks 1-5':aveToFeedback(this.arAverage(q17_t).toFixed(0)),
          'Q29. I am able to change my actions flexibly in changing situations. 1-5':aveToFeedback(this.arAverage(q29_t).toFixed(0)),
          */
          })
           // row 4
          result_o.push({           
            'Språk':'Engelsk(' +countArry(3, l_d) +')',
            'Kön':'Man('+countArry(2, g_d)+')',
            'Q2.Framtid':'Lyceum('+countArry(2, q2_t)+')',
            '4.1 Aktivitet':countArry(1,q4_1_t),
            '4.2 Aktivitet':countArry(1,q4_2_t),
            '4.3 Aktivitet':countArry(1,q4_3_t),
            '4.4 Aktivitet':countArry(1,q4_4_t),
            '4.5 Aktivitet':countArry(1,q4_5_t),
            '4.6 Aktivitet':countArry(1,q4_6_t),
            '4.7 Aktivitet':countArry(1,q4_7_t),
            '4.8 Aktivitet':countArry(1,q4_8_t),
            '4.9 Aktivitet':countArry(1,q4_9_t),
            '4.10 Aktivitet':countArry(1,q4_10_t),
            '4.11 Aktivitet':countArry(1,q4_11_t),
            '5.Modersmål':this.arAverage(q5_t).toFixed(2),
            '6.Matemat':this.arAverage(q6_t).toFixed(2),
          /*  ..._op ==2 && {'Q4.1. Hobby / Music (0=no,1=yes)':countArry(1,q4_1_t) + '/' + q4_1_t.length},
            ..._op ==2 && {'Q4.2. Hobby / Sports':countArry(1,q4_2_t) + '/' + q4_2_t.length},
            ..._op ==2 && {'Q4.3. Hobby / Pets':countArry(1,q4_3_t) + '/' + q4_3_t.length},
            ..._op ==2 && {'Q4.4. Hobby / Movies':countArry(1,q4_4_t) + '/' + q4_4_t.length},
            ..._op ==2 && {'Q4.5. Hobby / Drawing':countArry(1,q4_5_t) + '/' + q4_5_t.length},
            ..._op ==2 && {'Q4.6. Hobby / Reading':countArry(1,q4_6_t) + '/' + q4_6_t.length},
            ..._op ==2 && {'Q4.7. Hobby / E-games':countArry(1,q4_7_t) + '/' + q4_7_t.length},
            ..._op ==2 && {'Q4.8. Hobby / Blog':countArry(1,q4_8_t) + '/' + q4_8_t.length},
            ..._op ==2 && {'Q4.9. Hobby / Organization':countArry(1,q4_9_t) + '/' + q4_9_t.length},
            ..._op ==2 && {'Q4.10. Hobby / Other':countArry(1,q4_10_t) + '/' + q4_10_t.length},
            ..._op ==2 && {'Q4.11. No hobbies':countArry(1,q4_11_t) + '/' + q4_11_t.length}, */
            '7.1 Familj':((countArry(1,q7_1_t) / q7_1_t.length)*100 ).toFixed(2) + ' %',
            '7.2 Familj':((countArry(1,q7_2_t) / q7_2_t.length)*100 ).toFixed(2) + ' %',
            '7.3 Familj':((countArry(1,q7_3_t) / q7_3_t.length)*100 ).toFixed(2) + ' %',
            '7.4 Familj':((countArry(1,q7_4_t) / q7_4_t.length)*100 ).toFixed(2) + ' %',
            '7.5 Familj':((countArry(1,q7_5_t) / q7_5_t.length)*100 ).toFixed(2) + ' %',
            '7.6 Familj':((countArry(1,q7_6_t) / q7_6_t.length)*100 ).toFixed(2) + ' %',
            '8.Företagarbyn':'Nej('+countArry(2, q8_t)+')',
            '9.UF osv.':'Nej('+countArry(2, q9_t)+')'}),
            // row 5
          result_o.push({            
            'Språk':'Svenska(' +countArry(2, l_d) +')',
            'Kön':'Kvinna('+countArry(1, g_d)+')',
            'Q2.Framtid':'HUX-utbildning('+countArry(3, q2_t)+')',
            '8.Företagarbyn':'Kan inte säga('+countArry(3, q8_t)+')',
            '9.UF osv.':'Kan inte säga('+countArry(3, q9_t)+')'})          
            // row 6
                  result_o.push({
                    'Språk':'Finska(' +countArry(1, l_d) +')',            
                    'Kön':'Vill inte svara(' + countArry(3, g_d)+ ')',
                    'Q2.Framtid':'Annat('+countArry(4, q2_t)+')'})
                    // row 7
              result_o.push({
                ..._op ==1 && {' ':'---Svenska---Landskap-----'},
                ..._op ==2 && {' ':'---Svenska---Kommun-----'},
                ..._op ==3 && {' ':'---Svenska---Finalnd-----'}})
            } 
       //if (reportLang = 3){ // report language is finnish
       if ($('*').find('#rlFin').is(':checked')) {
              // processing new array for Finnish report
            
              result_o.push({
                'Alkupäivä':this.formReportRequest.startDate.toISOString().split('T')[0],
                'Loppupäivä':this.formReportRequest.endDate.toISOString().split('T')[0],
                ..._op ==1 && {'Maakunta Numero':c_code},
                ..._op ==1 && {'Maakunta':c_name},
      
                ..._op ==2 && {'Kunta Numero':m_code},
                ..._op ==2 && {'Kunta':m_name},
                ..._op ==2 && {'Kuntaryhmä':' '},
                ..._op ==2 && {'Maakunta Numero':c_code},
                ..._op ==2 && {'Maakunta':c_name},
      
              'Vastausten määrä':this.summary.results,        
              'Kieli':' ',
              'Sukupuoli':' ',
              ' ':"Data : ",
              'Q2.Tulevaisuus':'Mihin haet ensisijaisesti opiskelemaan peruskoulun jälkeen?',
              '4.1 Harrastus':'Mitä seuraavista asioista harrastat kouluajan ulkopuolella säännöllisesti? Ei / Kyllä',
              '4.2 Harrastus':'Mitä seuraavista asioista harrastat kouluajan ulkopuolella säännöllisesti? Ei / Kyllä',
              '4.3 Harrastus':'Mitä seuraavista asioista harrastat kouluajan ulkopuolella säännöllisesti? Ei / Kyllä',
              '4.4 Harrastus':'Mitä seuraavista asioista harrastat kouluajan ulkopuolella säännöllisesti? Ei / Kyllä',
              '4.5 Harrastus':'Mitä seuraavista asioista harrastat kouluajan ulkopuolella säännöllisesti? Ei / Kyllä',
              '4.6 Harrastus':'Mitä seuraavista asioista harrastat kouluajan ulkopuolella säännöllisesti? Ei / Kyllä',
              '4.7 Harrastus':'Mitä seuraavista asioista harrastat kouluajan ulkopuolella säännöllisesti? Ei / Kyllä',
              '4.8 Harrastus':'Mitä seuraavista asioista harrastat kouluajan ulkopuolella säännöllisesti? Ei / Kyllä',
              '4.9 Harrastus':'Mitä seuraavista asioista harrastat kouluajan ulkopuolella säännöllisesti? Ei / Kyllä',
              '4.10 Harrastus':'Mitä seuraavista asioista harrastat kouluajan ulkopuolella säännöllisesti? Ei / Kyllä',
              '4.11 Harrastus':'Mitä seuraavista asioista harrastat kouluajan ulkopuolella säännöllisesti? Ei / Kyllä',
              '5.Äidinkieli':'5.Mikä oli äidinkielen arvosanasi viimeisimmässä koulutodistuksessasi? 4-10',
              '6.Matemat':'6.Mikä oli matematiikan arvosanasi viimeisimmässä koulutodistuksessasi? 4-10',
              '7.1 Perhe':'Onko perheessäsi yrittäjiä? Ei / Kyllä',
              '7.2 Perhe':'Onko perheessäsi yrittäjiä? Ei / Kyllä',
              '7.3 Perhe':'Onko perheessäsi yrittäjiä? Ei / Kyllä',
              '7.4 Perhe':'Onko perheessäsi yrittäjiä? Ei / Kyllä',
              '7.5 Perhe':'Onko perheessäsi yrittäjiä? Ei / Kyllä',
              '7.6 Perhe':'Onko perheessäsi yrittäjiä? Ei / Kyllä',
              '8.Yrityskylä':'8.Oletko käynyt Yrityskylässä? ',
              '9.JA etc.':'9.Oletko ollut mukana Nuori Yrittäjyys -toiminnassa (esim. NY Vuosi yrittäjänä, Pikkuyrittäjät)?',
              '10.Y Tietoa':'10.Koulu on tarjonnut minulle tarpeeksi tietoa yrittäjyydestä. 1-5',
              '11.Yhteistyö':'11.Teemme koulussa paljon yhteistyötä yritysten kanssa. 1-5',
              '12. +Yhteistyö':'Koulussa tehtävästä yhteistyöstä yritysten kanssa on minulle hyötyä',
              '13.Keskustelu1 Koulu':'13.Keskustelemme usein yrittäjyydestä koulussa. 1-5',
              '14.Keskustelu2 Koti':'14.Keskustelemme usein yrittäjyydestä kotona. 1-5',
              '15.Keskustelu3 Kaverit':'15.Keskustelemme usein yrittäjyydestä kavereiden kanssa. 1-5',
              '16.Tavoite':'16.Olen asettanut itselleni tavoitteita esimerkiksi kouluun tai harrastuksiin liittyen. 1-5',
              '17. +Projekti':'17.Tiedän, että suoriudun vaikeistakin projekteista ja/tai tehtävistä. 1-5',
              '18.Hyvä jossakin':'18.Tiedän missä asioissa olen hyvä. 1-5',
              '19.Usko itseen':'19.Uskon itseeni. 1-5',
              '20.Sinnikäs':'20.Olen sinnikäs. 1-5',
              '21.Luova':'21.Olen luova. 1-5',
              '22.Parempi':'22.Haluan tehdä asiat muita paremmin. 1-5',
              '23.Paine':'23.Työskentelen hyvin paineen alla. 1-5',
              '24.Vaikutus':'Voin vaikuttaa oman elämäni kulkuun. 1-5',
              '25.Ideat':'25.Olen kaveripiirissäni usein se, joka keksii uudet ideat. 1-5',
              '26.Riskin otto':'26.Kokeillessani uusia asioita voin ottaa riskejä. 1-5',
              '27.Välttely':'27.Välttelen tilanteita, joissa en tiedä mitä pitää tehdä. 1-5',
              '28.Vastoinkäymiset':'28.Pysyn tavoitteessani, vaikka kohtaisin vastoinkäymisiä. 1-5',
              '29.Joustavuus':'29.Muutan joustavasti toimintaani muuttuvissa tilanteissa. 1-5',
              '30.Yrittäjä?':'30.Voisin toimia yrittäjänä 1-5',
              '31. +Yrittäjä':'31.Minusta olisi hienoa jos minusta tulisi yrittäjä 1-5',
              '32.Y talous':'32.Ymmärrän miten yrityksen talous toimii 1-5',
              '33. +Projekti':'33.Jag kan planera ett projekt 1-5',
              '34. Liiketoiminta suunnittelu':'34.Osaan laatia liiketoimintasuunnitelman 1-5',
              '35. Yritys':'35.Haluan tulevaisuudessa perustaa yrityksen'
              },{
              'Q2.Tulevaisuus':'Ammatillinen('+countArry(1, q2_t)+')',
              '4.1 Harrastus':'Musiikki',
              '4.2 Harrastus':'Liikunta',
              '4.3 Harrastus':'Lemmikit',
              '4.4 Harrastus':'Elokuvat, konsertit',
              '4.5 Harrastus':'Taiteet(Piirustus, maalaus, valokuvaus)',
              '4.6 Harrastus':'Lukeminen',
              '4.7 Harrastus':'E-pelit(Tietokone- tai konsolipelit) ',
              '4.8 Harrastus':'Blog-vlog-video',
              '4.9 Harrastus':'Yhdistystoiminta',
              '4.10 Harrastus':'Muu',
              '4.11 Harrastus':'Ei harrastuksia',
              '7.1 Perhe':'äiti',
              '7.2 Perhe':'isä',
              '7.3 Perhe':'Isovanhempi',
              '7.4 Perhe':'Sisarus',
              '7.5 Perhe':'Muu läheinen henkilö',
              '7.6 Perhe':'Ei Perheessäni tai läheisissäni ei ole yrittäjiä ',        
              '8.Yrityskylä':'Kyllä('+countArry(1, q8_t)+')',
              '9.JA etc.':'Kyllä('+countArry(1, q9_t)+')',
              '10.Y Tietoa':this.arAverage(q10_t).toFixed(2),
              '11.Yhteistyö':this.arAverage(q11_t).toFixed(2),
              '12. +Yhteistyö':this.arAverage(q12_t).toFixed(2),
              '13.Keskustelu1 Koulu':this.arAverage(q13_t).toFixed(2),
              '14.Keskustelu2 Koti':this.arAverage(q14_t).toFixed(2),
              '15.Keskustelu3 Kaverit':this.arAverage(q15_t).toFixed(2),
              '16.Tavoite':this.arAverage(q16_t).toFixed(2),
              '17. +Projekti':this.arAverage(q17_t).toFixed(2),
              '18.Hyvä jossakin':this.arAverage(q18_t).toFixed(2),
              '19.Usko itseen':this.arAverage(q19_t).toFixed(2),
              '20.Sinnikäs':this.arAverage(q20_t).toFixed(2),
              '21.Luova':this.arAverage(q21_t).toFixed(2),
              '22.Parempi':this.arAverage(q22_t).toFixed(2),
              '23.Paine':this.arAverage(q23_t).toFixed(2),
              '24.Vaikutus':this.arAverage(q24_t).toFixed(2),
              '25.Ideat':this.arAverage(q25_t).toFixed(2),
              '26.Riskin otto':this.arAverage(q26_t).toFixed(2),
              '27.Välttely':this.arAverage(q27_t).toFixed(2),
              '28.Vastoinkäymiset':this.arAverage(q28_t).toFixed(2),
              '29.Joustavuus':this.arAverage(q29_t).toFixed(2),
              '30.Yrittäjä?':this.arAverage(q30_t).toFixed(2),
              '31. +Yrittäjä':this.arAverage(q31_t).toFixed(2),
              '32.Y talous':this.arAverage(q32_t).toFixed(2),
              '33. +Projekti':this.arAverage(q33_t).toFixed(2),
              '34. Liiketoiminta suunnittelu':this.arAverage(q34_t).toFixed(2),
              '35. Yritys':this.arAverage(q35_t).toFixed(2),
                   
             /*   },{
                ' ':"--------",
               'Q16. I have set myself goals related to school and/or hobbies 1-5':aveToFeedback(this.arAverage(q16_t).toFixed(0)),
                'Q17. I know that I am able to complete difficult projects and/or tasks 1-5':aveToFeedback(this.arAverage(q17_t).toFixed(0)),
                'Q29. I am able to change my actions flexibly in changing situations. 1-5':aveToFeedback(this.arAverage(q29_t).toFixed(0)),
                */
                })
                 // row 4
                result_o.push({           
                  'Kieli':'Englanti(' +countArry(3, l_d) +')',
                  'Sukupuoli':'Mies('+countArry(2, g_d)+')',
                  'Q2.Tulevaisuus':'Lukio('+countArry(2, q2_t)+')',
                  '4.1 Harrastus':countArry(1,q4_1_t),
                  '4.2 Harrastus':countArry(1,q4_2_t),
                  '4.3 Harrastus':countArry(1,q4_3_t),
                  '4.4 Harrastus':countArry(1,q4_4_t),
                  '4.5 Harrastus':countArry(1,q4_5_t),
                  '4.6 Harrastus':countArry(1,q4_6_t),
                  '4.7 Harrastus':countArry(1,q4_7_t),
                  '4.8 Harrastus':countArry(1,q4_8_t),
                  '4.9 Harrastus':countArry(1,q4_9_t),
                  '4.10 Harrastus':countArry(1,q4_10_t),
                  '4.11 Harrastus':countArry(1,q4_11_t),
                  '5.Äidinkieli':this.arAverage(q5_t).toFixed(2),
                  '6.Matemat':this.arAverage(q6_t).toFixed(2),
                /*  ..._op ==2 && {'Q4.1. Hobby / Music (0=no,1=yes)':countArry(1,q4_1_t) + '/' + q4_1_t.length},
                  ..._op ==2 && {'Q4.2. Hobby / Sports':countArry(1,q4_2_t) + '/' + q4_2_t.length},
                  ..._op ==2 && {'Q4.3. Hobby / Pets':countArry(1,q4_3_t) + '/' + q4_3_t.length},
                  ..._op ==2 && {'Q4.4. Hobby / Movies':countArry(1,q4_4_t) + '/' + q4_4_t.length},
                  ..._op ==2 && {'Q4.5. Hobby / Drawing':countArry(1,q4_5_t) + '/' + q4_5_t.length},
                  ..._op ==2 && {'Q4.6. Hobby / Reading':countArry(1,q4_6_t) + '/' + q4_6_t.length},
                  ..._op ==2 && {'Q4.7. Hobby / E-games':countArry(1,q4_7_t) + '/' + q4_7_t.length},
                  ..._op ==2 && {'Q4.8. Hobby / Blog':countArry(1,q4_8_t) + '/' + q4_8_t.length},
                  ..._op ==2 && {'Q4.9. Hobby / Organization':countArry(1,q4_9_t) + '/' + q4_9_t.length},
                  ..._op ==2 && {'Q4.10. Hobby / Other':countArry(1,q4_10_t) + '/' + q4_10_t.length},
                  ..._op ==2 && {'Q4.11. No hobbies':countArry(1,q4_11_t) + '/' + q4_11_t.length}, */
                  '7.1 Perhe':((countArry(1,q7_1_t) / q7_1_t.length)*100 ).toFixed(2) + ' %',
                  '7.2 Perhe':((countArry(1,q7_2_t) / q7_2_t.length)*100 ).toFixed(2) + ' %',
                  '7.3 Perhe':((countArry(1,q7_3_t) / q7_3_t.length)*100 ).toFixed(2) + ' %',
                  '7.4 Perhe':((countArry(1,q7_4_t) / q7_4_t.length)*100 ).toFixed(2) + ' %',
                  '7.5 Perhe':((countArry(1,q7_5_t) / q7_5_t.length)*100 ).toFixed(2) + ' %',
                  '7.6 Perhe':((countArry(1,q7_6_t) / q7_6_t.length)*100 ).toFixed(2) + ' %',
                  '8.Yrityskylä':'Ei('+countArry(2, q8_t)+')',
                  '9.JA etc.':'Ei('+countArry(2, q9_t)+')'}),
                  // row 5
                result_o.push({            
                  'Kieli':'Ruotsi(' +countArry(2, l_d) +')',
                  'Sukupuoli':'Nainen('+countArry(1, g_d)+')',
                  'Q2.Tulevaisuus':'TUVA('+countArry(3, q2_t)+')',
                  '8.Yrityskylä':'En osaa sanoa('+countArry(3, q8_t)+')',
                  '9.JA etc.':'En osaa sanoa('+countArry(3, q9_t)+')'})
                
                  // row 6
                  result_o.push({
                    'Kieli':'Suomi(' +countArry(1, l_d) +')',            
                    'Sukupuoli':'En halua sanoa(' + countArry(3, g_d)+ ')',
                    'Q2.Tulevaisuus':'Muu('+countArry(4, q2_t)+')'})
                    // row 7
              result_o.push({
                ..._op ==1 && {' ':'---Suomi---Maakunta-----'},
                ..._op ==2 && {' ':'---Suomi---kunta-----'},
                ..._op ==3 && {' ':'---Suomi---Maa-----'}})
            } 

        return result_o;
      }
      arAverage = arr => arr.reduce((a,b) => a + b, 0) / arr.length;
      arAverageP = arr => arr.reduce((a,b) => a + b, 0) % arr.length;
      arSum = arr => arr.reduce((a,b) => a + b, 0);
      changeDataGender(x, y, z, a, b, c) {
        let newData = {
          labels: [a, b, c],
          datasets: [
              {
                  data: [x, y, z],
                  backgroundColor: [
                      "#FF9884",
                      "#3aa2EB",
                      "#a076f6"
                  ],
                  hoverBackgroundColor: [
                    "#FF9884",
                    "#3aa2EB",
                    "#a076f6"
                ]
              }]    
          }
        this.chartDataG = Object.assign({}, newData);
}
changeDataLanguage(x, y, z, a, b, c) {
  let newData = {
    labels: ['Language' ],
    datasets: [
        {
            label: a,
            backgroundColor: '#42A5F5',
            borderColor: '#1E88E5',
            data: [x]
        },
        {
            label: b,
            backgroundColor: '#9CCC65',
            borderColor: '#7CB342',
            data: [y]
        },
        {
            label: c,
            backgroundColor: '#0gCC65',
            borderColor: '#7CB342',
            data: [z]
        }
    ]   
    }
  this.chartDataL = Object.assign({}, newData);
}
      onKeyA(value) { 
        if (value != '') {
         this.provienceList = this.searchSTR(value);
          } 
        }

          searchSTR(value: string) { 
            if (value != '') {
            let filter = value.toLowerCase();
            return this.provienceList.filter(option => option.toLowerCase().startsWith(filter));
            } else {
              return this.provienceList
            }
          }
          generateCharts(arr: any[], arg2: number, c:string, m:string) {
           this.resetChart()
           let result:any = {}
           this.summary.County = c;
           this.summary.Municipality = m;
           let g_data = []; let l_data = []
            if (arg2 == 1) {
              result = arr.filter(function(obj) {
              return obj["AE: County name"] == c;
           });             
          }
          if (arg2 == 2) {
            result = arr.filter(function(obj) {
            return obj["AG: Q3B. Municipality name"] == m;
         }); 
        }
        if (arg2 == 3){
          result = arr
        }
        if (result.length == 0) this.summaryReport = false;
        this.summary.results = result.length
                 for (const val_ in result){
                  for (const k of Object.keys(result[val_])){
                 //  console.log(k + '... '+ this.e_workbook[val_][k])                 
                   if (k == 'AK: Q1. Gender 1.woman 2.man 3.prefer not answer'){
                     g_data.push(result[val_][k])
                   }
                   if (k == 'AJ: Language. 1. FIN  2. SWE  3. ENG '){
                     l_data.push(result[val_][k])
                   }
                  }
                }  
                this.changeDataGender(countArry(1, g_data),countArry(2, g_data),countArry(3, g_data),'Female', 'Male', 'Others')
                this.changeDataLanguage(countArry(1, l_data),countArry(2, l_data),countArry(3, l_data),'Finnish','Swedish','English')     
        }

  } // class

 // not in use functions , instead sorting is done at api server.
function iterateQuestions(obj: any) { 
           for(let prop in obj) {
             if (typeof(obj[prop]) == "object") {
               iterateQuestions(obj[prop]);
             } else {
               if (prop == "_id" || prop == "mtee_question_text" ){
                 }
             }
             }
}
function updateQestion(x: any, y: number) {
  $('#'+ x).find('span.info1').text('Editing...' );
  $('*').find('span.info2').text('Question :' + ((x+1)+(y - 10)) + ' is not saved...' );
   $('#'+ x).get(0).scrollIntoView();
  //document.getElementById('q_fin').focus();
  document.getElementById('pageMenu').style.visibility = "hidden";
  }
function countArry(arg0: any, _data: any[]): number {
let op = 0;
  for (const val in _data) { if (_data[val] === arg0) op = (op+1)}
  return op
}

function applyFix(result_t: any[]): any {
  for (let val_ in result_t){  
    for (let k of Object.keys(result_t[val_])){
     //console.log(k + '... '+ result_t[val_][k])
     //counties
     if (result_t[val_]['AD: County code'] == 6){
      result_t[val_]['AE: County name'] = 'Päijät-Häme';
    }
    if (result_t[val_]['AD: County code'] == 13){
      result_t[val_]['AE: County name'] = 'Etelä-Pohjanmaa'; 
    }
    if (result_t[val_]['AD: County code'] == 9){
      result_t[val_]['AE: County name'] = 'Etelä-Savo';
    }
    if (result_t[val_]['AD: County code'] == 4){
      result_t[val_]['AE: County name'] = 'Kanta-Häme';
    }
    if (result_t[val_]['AD: County code'] == 8){
      result_t[val_]['AE: County name'] = 'Etelä-Karjala';
    }
    // municipalities
    if (result_t[val_]['AF: Q3A. Municipality code'] == 109){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Hämeenlinna';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 992){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Äänekoski';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 989){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Ähtäri';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 5){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Alajärvi';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 35){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Brändö';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 47){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Enontekiö';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 62){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Föglö';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 60){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Finström';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 108){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Hämeenkyrö';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 69){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Haapajärvi';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 106){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Hyvinkää';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 182){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Jämsä';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 186){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Järvenpää';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 179){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Jyväskylä';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 317){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Kärsämäki';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 318){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Kökar';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 271){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Kokemäki';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 403){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Lappajärvi';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 418){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Lempäälä';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 505){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Mäntsälä';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 508){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Mänttä-Vilppula';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 507){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Mäntyharju';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 483){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Merijärvi';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 503){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Mynämäki';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 545){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Närpiö';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 543){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Nurmijärvi';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 635){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Pälkäne';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 636){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Pöytyä';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 593){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Pieksämäki';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 615){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Pudasjärvi';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 624){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Pyhtää';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 689){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Rautjärvi';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 691){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Reisjärvi';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 694){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Riihimäki';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 729){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Saarijärvi';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 743){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Seinäjoki';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 749){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Siilinjärvi';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 889){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Utajärvi';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 941){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Vårdö';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 946){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Vöyri';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 925){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Vieremä';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 980){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Ylöjärvi';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 981){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Ypäjä';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 90){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Heinävesi';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 441){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Luumäki';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 43){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Eckerö';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 592){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Petäjävesi';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 312){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Kyyjärvi';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 261){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Kittilä';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 758){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Sodankylä';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 152){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Isokyrö';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 607){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Polvijärvi';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 859){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Tyrnävä';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 781){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Sysmä';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 316){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Kärkölä';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 214){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Kankaanpää';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 181){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Jämijärvi';
    }
    if (result_t[val_]['AF: Q3A. Municipality code'] == 322){
      result_t[val_]['AG: Q3B. Municipality name'] = 'Kemiönsaari';
    }
    }
  } 
   return result_t;
}


function aveToFeedback(arg0: any) {
  let C = '';
  if (arg0 == 1) C = 'Completely Disagree';
  if (arg0 == 2) C = 'Disagree';
  if (arg0 == 3) C = 'Not agree or Disagree';
  if (arg0 == 4) C = 'Agree';
  if (arg0 == 5) C = 'Completely Agree';
  return C
}

