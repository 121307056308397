import { AdminGuard } from './admin.guard';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MultiSelectModule} from 'primeng/multiselect';
import {ChartModule} from 'primeng/chart';
import { TokenInterceptorService } from './token-interceptor.service';
import {
  MatToolbarModule,
  MatMenuModule,
  MatIconModule,
  MatCardModule,
  MatSnackBar,
  MatButtonModule,
  MatTableModule,
  MatDividerModule,
  MatSelectModule,
  MatFormFieldModule,
  MatInputModule,
  MatRippleModule,
  MatProgressSpinnerModule, MatStepperModule, MatProgressBarModule, MatRadioModule, MatCheckboxModule, MatAutocompleteModule
} from '@angular/material';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LogService } from './logger.service';
import { WebserviceCallerService } from './webservice-caller.service';
import { DataShareService } from './data-share.service';
import { QuestionsComponent } from './questions/questions.component';
import { AppexitComponent } from './appexit/appexit.component';
import {DropdownModule} from 'primeng';
import { ReportComponent } from './report/report.component';
import { AdminComponent } from './admin/admin.component';
import { InfoComponent } from './info/info.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import { QurPipe } from './qur.pipe';
import { HighlightDirective } from './highlight.pipe';
import { AdminloginComponent } from './adminlogin/adminlogin.component';
import {DragDropModule} from 'primeng/dragdrop';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    QuestionsComponent,
    AppexitComponent,
    ReportComponent,
    AdminComponent,
    InfoComponent,
    QurPipe,
    HighlightDirective,
    AdminloginComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatCardModule,
    MatSnackBarModule,
    MatButtonModule,
    MatTableModule,
    MatDividerModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    ChartModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    MatStepperModule,
    MatProgressBarModule,
    FormlyBootstrapModule,
    FormlyModule.forRoot({
      validationMessages: [
        {name: 'required', message: 'This field is required'},
      ],
    }),
    MatRadioModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MultiSelectModule,
    DropdownModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    DragDropModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    LogService,
    WebserviceCallerService,
    DataShareService,
    AdminGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi : true
    },
    QurPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
