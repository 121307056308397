import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { delay } from 'rxjs/operators';
import { AdminService } from './admin.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  public data__: String = '';

  constructor(public _router : Router, public  adminApi : AdminService){ }
  canActivate(): boolean {
    //return !!this.adminApi.loggedIn()

    if (this.adminApi.getToken() && this.adminApi.getLoggedU()) {
      this.adminApi.verifyLogedinU().subscribe((r: string)=> this.data__ = r);
        if (this.data__!= 'fail') {
          return true;
          } else {
            this._router.navigate(['/adminlogin'])
            return false
          }
    } else {
        this._router.navigate(['/adminlogin'])
        return false
        
    }
  }
/*
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
return false
    }
  canLoad(
    route: Route,
    segments: UrlSegment[]): boolean {
return false
    }
    */
}
function setRdata() {
  this.adminApi.verifyLogedinU().subscribe((r: string)=> this.data__ = r);
      console.log(this.data__)
}

